import React from 'react';
import { MessageDictionary } from '../utilities/MessageDictionary';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true };
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false };
    case 'SIGN_OUT_SUCCESS':
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem('authToken')
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, createUser, signOut };

// ###########################################################

async function loginUser(dispatch, login, password, history, setIsLoading, setError, onLogin) {
  setError(null);
  setIsLoading(true);

  if (!!login && !!password) {
    const hasAuthenticated = await onLogin(login, password);

    if (hasAuthenticated) {
      setError(null);
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS' });
      history.push({ pathname: '/app/overview', search: window.location.search });
    } else {
      dispatch({ type: 'LOGIN_FAILURE' });
      setError(MessageDictionary.AUTHENTICATION_FAILED);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(MessageDictionary.AUTHENTICATION_FAILED);
    setIsLoading(false);
  }
}

async function createUser(
  dispatch,
  firstName,
  lastName,
  login,
  password,
  history,
  setIsLoading,
  setError,
  onUserCreate
) {
  setError(null);
  setIsLoading(true);

  if (!!firstName && !!lastName && !!login && !!password) {
    const hasAuthenticated = await onUserCreate(firstName, lastName, login, password);

    if (hasAuthenticated) {
      setError(null);
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS' });
      history.push({ pathname: '/app/overview', search: window.location.search });
    } else {
      dispatch({ type: 'LOGIN_FAILURE' });
      setError(MessageDictionary.USER_CREATION_FAILED);
      setIsLoading(false);
    }
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(MessageDictionary.USER_CREATION_FAILED);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history, onLogout) {
  await onLogout();

  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push({ pathname: '/login', search: window.location.search });
}
