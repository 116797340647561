import axios from 'axios';

let axiosInstance;

class ContainedAxiosFactory {
  constructor() {
    if (!axiosInstance) {
      axiosInstance = axios.create();
    }
  }

  getInstance() {
    return axiosInstance;
  }
}

export default ContainedAxiosFactory;
