const OrchestrateAppActionTypes = Object.freeze({
  APP_INSTANTIATED: '@@OrchestrateApp/APP_INSTANTIATED',
  SET_APPLICATION_EXCEPTION: '@@OrchestrateApp/SET_APPLICATION_EXCEPTION',
  SET_REFRESH_TOKEN_EXCEPTION: '@@OrchestrateApp/SET_REFRESH_TOKEN_EXCEPTION',
  CLEAR_APPLICATION_EXCEPTION: '@@OrchestrateApp/CLEAR_APPLICATION_EXCEPTION',
  REQUEST_SERVICE_DISCOVERY: '@@OrchestrateApp/RequestServiceDiscovery',
  REQUEST_SERVICE_DISCOVERY_OK: '@@OrchestrateApp/RequestServiceDiscoveryOk',
  REQUEST_SERVICE_DISCOVERY_ERR: '@@OrchestrateApp/RequestServiceDiscoveryErr'
});

export { OrchestrateAppActionTypes };
