import React, { useEffect, useState, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useTheme } from '@material-ui/styles';
import { ResponsiveContainer, ComposedChart, Line, YAxis, XAxis, Tooltip, Bar, CartesianGrid } from 'recharts';
import { dashboardTransactionsChartDataNormalizer, fullDateComparisonPredicate } from '../../../../utilities/chart';
import { dataResolverForUseEffect } from '../../../../utilities/generic';
import { TransactionsComposedChartTooltip } from './TransactionsComposedChartTooltip';
import { EmptyCard } from '../../../../components/EmptyCard';
import { useFilteringDates } from '../../../../hooks';

const TransactionsComposedChart = ({ data, chartPeriod, isLoading }) => {
  const theme = useTheme();
  const [dashboardData, setDashboardData] = useState([]);
  const { startDate, endDate } = useFilteringDates(chartPeriod, true);

  useEffect(() => {
    if (data || !isEmpty(data)) {
      dataResolverForUseEffect(dashboardTransactionsChartDataNormalizer, [data, 'fullDate'], setDashboardData);
    }
  }, [data]);

  const filteredData = useMemo(() => {
    if (typeof startDate === 'number' && typeof endDate === 'number' && !isEmpty(dashboardData)) {
      const predicate = fullDateComparisonPredicate(startDate, endDate);
      return dashboardData.filter(predicate);
    }
    return dashboardData;
  }, [startDate, endDate, dashboardData]);

  const period = chartPeriod.includes('last') ? `for the ${chartPeriod}` : `from ${chartPeriod}`;

  if (isLoading || !filteredData || isEmpty(filteredData)) {
    return (
      <EmptyCard isLoading={isLoading} content={{ title: 'transactions' }}>
        Sorry, no matching records found {period}
      </EmptyCard>
    );
  }

  return (
    <>
      <ResponsiveContainer width='100%' minWidth={500} height={350}>
        <ComposedChart margin={{ top: 20, right: -15, left: -15, bottom: 0 }} data={filteredData}>
          <XAxis dataKey='fullDate' />
          <YAxis />
          <Tooltip payload={filteredData} content={<TransactionsComposedChartTooltip />} />
          <CartesianGrid stroke='#f5f5f5' />
          <Bar dataKey='volumeOfTransactions' barSize={20} fill={theme.palette.warning.main} />
          <Line type='monotone' dataKey='valueOfTransactions' stroke={theme.palette.primary.main} />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export { TransactionsComposedChart };
