import ServiceDiscoveryFactory from './factories/ServiceDiscoveryFactory';
import find from 'lodash/find';

/**
 * Get the endpoint of a requested service from the service discovery list with same host as fallback
 * wrapped with relay service if required to avoid mixed content blocking
 *
 * @param {string} serviceId
 * @param {string} relativePath
 * @param {boolean} useRelay
 * @param {boolean} forceRelay
 *
 * @returns string
 */
const getServiceHostEndpoint = (serviceId, relativePath, useRelay = false, forceRelay = false) => {
  const sameHost = 'https://inqredi-yapily.herokuapp.com';
  const serviceDetailsList = new ServiceDiscoveryFactory().getServiceDiscoveryList();
  const serviceDetails = find(serviceDetailsList, ['serviceId', serviceId]);
  const serviceHost =
    serviceDetails && serviceDetails.instanceInfo && serviceDetails.instanceInfo.homePageUrl
      ? serviceDetails.instanceInfo.homePageUrl
      : sameHost;

  return wrapUrlWithRelay(`${serviceHost}${relativePath}`, useRelay, forceRelay);
};

/**
 * Takes a URL and returns a relay wrapped URL if the current protocol is https to avoid mixed-content
 * blocking of the request or CORS authentication issues
 *
 * @param {string} targetUrl
 * @param {boolean} useRelay
 * @param {boolean} forceRelay
 *
 * @returns string
 */
const wrapUrlWithRelay = (targetUrl, useRelay = false, forceRelay = false) => {
  const sameHost = window.location.origin;
  const shouldUseCORSRelay =
    forceRelay || (targetUrl.startsWith('https:') && !targetUrl.startsWith(sameHost) && useRelay);
  const shouldUseHttpRelay = window.location.protocol === 'https:' && targetUrl.startsWith('http:') && useRelay;
  if (shouldUseCORSRelay) {
    return `${sameHost}/api/httpsRelay?resource=${targetUrl}`;
  } else if (shouldUseHttpRelay) {
    return `${sameHost}/api/httpRelay?resource=${targetUrl}`;
  } else {
    return targetUrl;
  }
};

/**
 * Takes a URL relative path and returns the full path for the same host
 *
 * @param {string} relativePath
 *
 * @returns string
 */
const getSameHostEndpoint = (relativePath) => {
  return `${window.location.origin}${relativePath}`;
};

/**
 * Takes a URL that has query string params and returns combined dynamically added params
 *
 * @param {string} url
 * @param {object} [queryParams]
 *
 * @returns object | undefined
 */
const getParamsForUrlWithQueryString = (url, queryParams) => {
  const urlParts = url.split('?');
  const hardcodedParamsString = urlParts[1] ? urlParts[1] : null;

  if (hardcodedParamsString) {
    const hardcodedParamsArray = hardcodedParamsString.split('&');
    const hardcodedParams = {};

    hardcodedParamsArray.forEach((paramString) => {
      const param = paramString.split('=');
      hardcodedParams[param[0]] = param[1] ? param[1] : undefined;
    });

    return queryParams ? { ...hardcodedParams, ...queryParams } : hardcodedParams;
  }

  return queryParams;
};

export { getServiceHostEndpoint, wrapUrlWithRelay, getSameHostEndpoint, getParamsForUrlWithQueryString };
