import { capitalizeFirstLetter } from '../String';
import ContainedAxiosFactory from './ContainedAxiosFactory';

let authHeaders;

class AuthTokenFactory {
  constructor(authToken, tokenType, shouldSetAxiosHeader) {
    if (authToken) {
      authHeaders = {
        Authorization: `${capitalizeFirstLetter(tokenType)} ${authToken}`
      };

      if (shouldSetAxiosHeader) {
        this.setToAxiosDefaults();
      }
    }
  }

  getServiceDiscoveryList() {
    return authHeaders;
  }

  setToAxiosDefaults() {
    const axiosInstance = new ContainedAxiosFactory().getInstance();
    axiosInstance.defaults.headers.common['Authorization'] = authHeaders['Authorization'];
  }

  removeFromAxiosDefaults() {
    const axiosInstance = new ContainedAxiosFactory().getInstance();
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
}

export default AuthTokenFactory;
