const authInitialState = {
  userDetails: null,
  entitlements: null,
  isAuthenticated: false,
  isAdmin: false,
  isMerchant: false,
  authToken: null,
  refreshToken: null,
  authTokenType: null,
  shouldFetchUserDetails: false
};

export default authInitialState;
