import React from 'react';
import { makeStyles, Card, CardContent, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: '100%',
    minHeight: '320px',
    backgroundColor: theme.palette.background.card,
    display: 'flex',
    alignItems: 'center'
  },
  cardContent: {
    maxWidth: '480px',
    margin: '0 auto',
    padding: theme.spacing(2)
  }
}));

export default function EmptyCard({ content, children, isLoading }) {
  const classes = useStyles();

  const { title } = content || {};

  if (children) {
    return (
      <Card variant='outlined' className={classes.card}>
        <CardContent className={classes.cardContent}>
          {isLoading ? (
            <Typography variant='subtitle1'>Loading {title}...</Typography>
          ) : (
            <Alert severity='info'>{children}</Alert>
          )}
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant='outlined' className={classes.card}>
      <CardContent className={classes.cardContent}>
        {isLoading ? (
          <Typography variant='subtitle1'>Loading {title}...</Typography>
        ) : (
          <Alert severity='info'>
            {`No ${title} are associated to the user.`}
            <br />
            {`If this is not as expected get in touch with robinpay support.`}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
}
