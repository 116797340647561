import isEmpty from 'lodash/isEmpty';
import authInitialState from './initialState';
import { AuthActionTypes } from './types';
import { setAuthTokenInStorage, setUserDetailsInStorage } from '../../utilities/storage';

const reducer = (state = authInitialState, { type, payload, meta }) => {
  switch (type) {
    case AuthActionTypes.SET_SHOULD_FETCH_USER_DETAILS:
      return { ...state, shouldFetchUserDetails: payload.data };

    case AuthActionTypes.REQUEST_USER_LOGIN_OK:
      setAuthTokenInStorage(payload);
      return {
        ...state,
        authToken: payload.data && payload.data.access_token,
        refreshToken: payload.data && payload.data.refresh_token,
        authTokenType: payload.data && payload.data.token_type
      };

    case AuthActionTypes.SET_REFRESHED_TOKENS:
      setAuthTokenInStorage(payload);
      return {
        ...state,
        authToken: payload.data && payload.data.access_token,
        refreshToken: payload.data && payload.data.refresh_token
      };

    case AuthActionTypes.REQUEST_USER_LOGIN_ERR:
      return {
        ...state,
        authToken: null,
        refreshToken: null,
        authTokenType: null
      };

    case AuthActionTypes.REQUEST_USER_DETAILS_OK:
      setUserDetailsInStorage(payload);
      return {
        ...state,
        userDetails: payload.data,
        isMerchant: payload.data && payload.data && !isEmpty(payload.data.merchantResponse),
        isAdmin: payload.data && payload.data && !isEmpty(payload.data.adminResponse)
      };

    case AuthActionTypes.SET_AUTH_STATE:
      return {
        ...state,
        ...payload
      };

    case AuthActionTypes.REQUEST_USER_DETAILS_ERR:
      return {
        ...state,
        userDetails: null,
        isMerchant: false,
        isAdmin: false
      };

    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS_OK:
      return {
        ...state,
        entitlements: payload.data
      };

    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS_ERR:
      return {
        ...state,
        entitlements: []
      };

    case AuthActionTypes.SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true
      };

    case AuthActionTypes.CLEAR_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
        userDetails: null,
        isMerchant: false,
        isAdmin: false,
        entitlements: null,
        authToken: null,
        refreshToken: null,
        authTokenType: null
      };

    default:
      return state;
  }
};

export { reducer as authReducer };
