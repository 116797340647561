export const ROBINPAY_CORE_SERVICE_ID = 'ROBINPAY_CORE';
export const ROBINPAY_YAPILY_SERVICE_ID = 'ROBINPAY_YAPILY';
export const ROBINPAY_CORE_SERVICE_CLIENT_ID = '4a0ac9e0-eeaa-4183-a330-d16f6492effd';
export const ROBINPAY_CORE_SERVICE_GRAND_TYPE = 'password';
export const ROBINPAY_CORE_SERVICE_REFRESH_TOKEN_GRAND_TYPE = 'refresh_token';

export const AUTH_TOKEN_REFRESH_INTERVAL = 20 * 60 * 1000; // 20 minutes
export const AUTH_TOKEN_REFRESH_PATH = '/oauth/token';

export const ALL_MERCHANTS_OPTION = { id: 0, label: 'All Merchants' };
export const ALL_STORES_OPTION = { id: 0, label: 'All Stores' };
