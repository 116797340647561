import React from 'react';
import { makeStyles, Card, CardContent, CardMedia, Avatar, Typography, Box } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { formatAmount } from '../../utilities/Math';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(0, 0, 0.5)
  },
  avatar: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(2, 2, 0)
  },
  card: {
    minWidth: '100%',
    backgroundColor: theme.palette.background.card
  },
  cardContent: {
    padding: theme.spacing(3, 0)
  }
}));

export default function UserCard(props) {
  const classes = useStyles();
  const { displayName, email, firstName, lastName, id, mobileNumber, totalSavings, avatarImg } = {
    ...props.userDetails
  };

  const renderTotalSavings = () => {
    const amounts = [];
    if (totalSavings && Array.isArray(totalSavings) && !isEmpty(totalSavings)) {
      totalSavings.forEach(({ amount, currency }) => {
        amounts.push(formatAmount(amount, currency));
      });
      return amounts.map((amount) => (
        <Typography key={amount} className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <AccountBalanceWalletIcon className={classes.avatar} fontSize='small' />
          Total savings: {`${amount}`}
        </Typography>
      ));
    }
    return null;
  };

  return (
    <Card variant='outlined' className={classes.card} style={{ display: 'inline-block' }}>
      <CardMedia align='center'>
        <Avatar alt={displayName} src={avatarImg} className={classes.large} />
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.text} color='textSecondary' variant='h5' align='center'>
          {displayName}
        </Typography>
        <Typography className={classes.text} color='textSecondary' variant='h6' align='center'>
          {`${firstName} ${lastName}`} ({`${props.verified ? 'verified' : 'non-verified'}`})
        </Typography>
        <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <AlternateEmailIcon className={classes.avatar} fontSize='small' /> {email}
        </Typography>
        {mobileNumber ? (
          <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
            <PhoneIcon className={classes.avatar} fontSize='small' />
            {mobileNumber} ({`${props.hasVerifiedPhone ? 'verified' : 'non-verified'}`})
          </Typography>
        ) : null}
        {renderTotalSavings()}
        <Box mt={4} />
        <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <PermIdentityIcon className={classes.avatar} fontSize='small' />
          User ID: {id}
        </Typography>
      </CardContent>
    </Card>
  );
}
