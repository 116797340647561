import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import { MessageDictionary } from './MessageDictionary';
import AxiosRequestObjectFactory from './factories/AxiosRequestObjectFactory';
import EndpointHandlerFactory from './factories/EndpointHandlerFactory';

/**
 * Takes a number of action/payload objects and returns a single Promise that resolves all in case you need too
 * group multiple operations in a synchronous blocking way
 *
 * Example:
 * input {PayloadConfig} - [{ action: getAwaitAPIData, payload: { data: ['dataItem'] }}]
 *
 * @param {PayloadConfig} payloadConfigs, fetchDataAction1, fetchDataAction2, fetchDataAction3
 *
 * @returns Promise
 */
const getDataFromDataService = async (...payloadConfigs) => {
  const fetchDataPromises = payloadConfigs.map(async (payloadConfig) => {
    const fetchDataAction = createDataServicePayload(
      payloadConfig.payload,
      payloadConfig.serviceFunction,
      payloadConfig.customErrorMsg
    );

    if (payloadConfig.dispatch && fetchDataAction.serviceFunction) {
      return payloadConfig.dispatch(
        fetchDataAction.serviceFunction(fetchDataAction.payload, fetchDataAction.customErrorMsg)
      );
    }

    return fetchDataAction.serviceFunction
      ? await fetchDataAction.serviceFunction(fetchDataAction.payload, fetchDataAction.customErrorMsg)
      : () => {
          throw new Error(MessageDictionary.NO_DATA_RETRIEVAL_FUNCTION);
        };
  });

  return fetchDataPromises.length > 1 ? await Promise.all(fetchDataPromises) : fetchDataPromises[0];
};

/**
 * Takes a number of action/payload objects and returns a single Promise that resolves all in case you need too
 * group multiple operations in an asynchronous non blocking way
 *
 * Example:
 * input {PayloadConfig} - [{ action: getAPIData, payload: { data: ['dataItem'] }}]
 *
 * @param {PayloadConfig} payloadConfigs, fetchDataAction1, fetchDataAction2, fetchDataAction3
 *
 * @returns Promise
 */
const getAsyncDataFromDataService = (...payloadConfigs) => {
  const fetchDataPromises = payloadConfigs.map((payloadConfig) => {
    const fetchDataAction = createDataServicePayload(
      payloadConfig.payload,
      payloadConfig.serviceFunction,
      payloadConfig.customErrorMsg
    );
    if (payloadConfig.dispatch && fetchDataAction.serviceFunction) {
      return payloadConfig.dispatch(
        fetchDataAction.serviceFunction(fetchDataAction.payload, fetchDataAction.customErrorMsg)
      );
    }

    return fetchDataAction.serviceFunction
      ? fetchDataAction.serviceFunction(fetchDataAction.payload, fetchDataAction.customErrorMsg)
      : () => {
          throw new Error(MessageDictionary.NO_DATA_RETRIEVAL_FUNCTION);
        };
  });

  return fetchDataPromises.length > 1 ? Promise.all(fetchDataPromises) : fetchDataPromises[0];
};

/**
 * Takes the argument required in order to construct a DataService action payload and a function of not the default
 * DataService getData should not be used
 *
 * @param {RequestConfigPayload} payloadConfig
 * @param {Function} [serviceFunction]
 * @param {string} [customErrorMsg]
 *
 * @returns PayloadConfig
 */
const createDataServicePayload = (payloadConfig, serviceFunction, customErrorMsg) => {
  const defaultPayloadConfig = {
    payload: {
      actionName: '',
      params: {},
      data: null
    }
  };

  return {
    serviceFunction:
      typeof serviceFunction === 'function'
        ? serviceFunction
        : () => {
            throw Error(MessageDictionary.NO_API_SERVICE_FUNCTION);
          },
    payload: merge(defaultPayloadConfig.payload, payloadConfig),
    customErrorMsg: customErrorMsg
  };
};

/**
 * Takes a DataService action payload argument and loops through endpoint configurations to fill in endpoint details
 *
 * @param {RequestConfigPayload} payload
 *
 * @returns AxiosRequestConfig | null
 */
const constructRequestConfig = (payload) => {
  let endpointDetails = {
    url: payload.url,
    params: payload.params,
    data: payload.data,
    method: payload.method
  };

  // Ensure that actionName has value to lookup on the endpoint handlers
  if (!payload.actionName) {
    throw Error(MessageDictionary.NO_API_ENDPOINT);
  }

  // Looping through all the endpoint handler configurations to construct the request config for the provided endpoint
  const endpointHandlers = new EndpointHandlerFactory().getHandlers();
  if (endpointHandlers) {
    endpointHandlers.forEach((endpointHandler) => {
      endpointDetails = endpointHandler(payload, endpointDetails);
    });
  }

  return AxiosRequestObjectFactory.createRequestObject(endpointDetails);
};

/**
 * Takes a DataService action error argument and transforms it to an error object of the ApplicationState
 *
 * @param {any} error
 * @param {string} [errorMessage]
 *
 * @returns RequestErrorDetails
 */
const transformResponseError = (error, errorMessage) => {
  if (error.response) {
    // Got a response that does not fall in range of 2xx
    return {
      statusCode: error.response.status,
      message: errorMessage || error.response.data.message
    };
  } else if (error.request) {
    // Got no response or malformed request object
    return {
      statusCode: error.code || 500,
      message: errorMessage || error.message || ''
    };
  }

  return {
    statusCode: 500,
    message: errorMessage || error.message || error
  };
};

/**
 * Takes an error object from Axios or Luna.Comm request calls and parses response contents if exist
 *
 * @param {any} error
 *
 * @returns string | undefined
 */
const handleResponseError = (error) => {
  let errorDetails;

  if (!error) {
    return undefined;
  }

  if (error.response) {
    // Got an error with response that has JSON data body
    const errorData = error.response.data;

    if (!isEmpty(errorData)) {
      try {
        errorDetails = JSON.parse(errorData.message);
        return (
          errorDetails.errorMessage ||
          errorData.errorMessage ||
          errorData.message ||
          errorData.error ||
          errorData ||
          undefined
        );
      } catch (e) {
        // Response Error with no data (eg. 404)
        if (typeof errorData === 'string') {
          return errorData;
        }

        errorDetails = `${errorData.error}${errorData.message ? ` ${errorData.message}` : ''}`;
        return errorDetails;
      }
    } else {
      const responseURL = error.response && error.response.request ? error.response.request.responseURL : '';
      return `${error.response.status}: ${error.response.statusText} - ${responseURL}`;
    }
  }

  const errorMessage =
    error.errorMessage || (typeof error.message === 'string' && !isEmpty(error.message))
      ? error.message
      : error.message && error.message.message
      ? error.message.message
      : error;

  // The request could not be set or fired, got no response or request object was malformed
  return errorMessage === 'Network Error' ? MessageDictionary.NETWORK_ERROR : errorMessage;
};

/**
 * Takes an error object from Axios calls and retrieves error stack trace
 *
 * @param {any} error
 *
 * @returns string | null
 */
const getResponseErrorStack = (error) => {
  if (error.response && error.response.data && error.response.data.message) {
    try {
      const errorDetails = JSON.parse(error.response.data.message);
      return errorDetails.stacktrace;
    } catch (e) {
      return null;
    }
  }

  return null;
};

/**
 * Handle REST response containing error details
 *
 * @param {any} response
 * @param {boolean} forceJsonResponse
 *
 * @returns any
 */
const handleResponseSuccess = (response, forceJsonResponse) => {
  const restErrorCode = 'APP.SERVER_ERROR';

  // Detect if an error is returned by REST response
  if (response.data.code && response.data.code === restErrorCode) {
    let errorMessage;

    try {
      let errorDetails = JSON.parse(response.data.message);
      errorMessage = errorDetails.errorMessage || response.data.message || response.data || response.data.code;
    } catch (e) {
      errorMessage = response.data.code;
    }

    throw new Error(errorMessage);
  }

  // Detect if response should be valid JSON and throw error if it is not
  if (forceJsonResponse && response && response.data !== '' && typeof response.data === 'string') {
    try {
      JSON.parse(response.data);
    } catch (error) {
      throw new Error(MessageDictionary.NOT_VALID_JSON_FORMAT);
    }
  }

  return response;
};

/**
 * Action naming convention suffix for success and error actions dispatched from DataService
 *
 * @param {string} actionName
 *
 * @returns ActionToDispatch
 */
const getDataServiceActionNames = (actionName) => {
  const successSuffix = 'Ok';
  const errorSuffix = 'Err';

  return {
    start: actionName,
    success: `${actionName}${successSuffix}`,
    error: `${actionName}${errorSuffix}`
  };
};

/**
 * Determine if data should be requested on page loads during componentDidUpdate/re-render phase
 *
 * @param {boolean} isNotInitialised
 * @param {boolean} isLoading
 * @param {any} errors
 *
 * @returns boolean
 */
const shouldGetData = (isNotInitialised, isLoading, errors) => {
  return isNotInitialised && !isLoading && !errors;
};

const jsonToFormSerialize = (object) => {
  let serializedData = '';
  let key;

  for (key in object) {
    serializedData += `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}&`;
  }

  const normalisedSerializedData = serializedData.slice(0, -1);

  return normalisedSerializedData;
};

const jsonToURI = (json) => {
  return encodeURIComponent(JSON.stringify(json));
};

const uriToJSON = (uriJson) => {
  return JSON.parse(decodeURIComponent(uriJson));
};

export {
  getDataFromDataService,
  getAsyncDataFromDataService,
  createDataServicePayload,
  transformResponseError,
  getResponseErrorStack,
  constructRequestConfig,
  handleResponseSuccess,
  handleResponseError,
  getDataServiceActionNames,
  shouldGetData,
  jsonToFormSerialize,
  jsonToURI,
  uriToJSON
};
