const globalSettingsEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    default:
      break;
  }

  return endpointDetails;
};

export default globalSettingsEndpointHandler;
