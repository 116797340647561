class RequestObjectFactory {
  static getRequestObject = (method = 'get') => {
    return {
      method: method,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      },
      params: {},
      url: window.location.origin,
      timeout: 10 * 1000 * 60 // Wait for 600 seconds
    };
  };
}

export default RequestObjectFactory;
