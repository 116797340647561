import { action } from 'typesafe-actions';
import defer from 'lodash/defer';
import isEmpty from 'lodash/isEmpty';
import {
  constructRequestConfig,
  getDataServiceActionNames,
  getResponseErrorStack,
  handleResponseError,
  handleResponseSuccess,
  transformResponseError
} from '../utilities/DataService';
import { OrchestrateAppActionTypes } from '../store/application/types';
import { throwApplicationException } from '../utilities/ErrorHandling';
import { MessageDictionary } from '../utilities/MessageDictionary';
import { isNotProductionMode } from '../utilities/Environment';
import ContainedAxiosFactory from '../utilities/factories/ContainedAxiosFactory';

class DataService {
  // Adding Axios response interceptors for logging purposes
  static setResponseInterceptor = new ContainedAxiosFactory().getInstance().interceptors.response.use(
    function logResponse(response) {
      isNotProductionMode() && console.log('%c Request Success:', 'color: #4CAF50; font-weight: bold', response);
      return response;
    },
    function logPromiseError(error) {
      isNotProductionMode() && console.log('%c Request Error:', 'color: #EC6060; font-weight: bold', error);
      return Promise.reject(error);
    }
  );

  static doRequest = (payload, customErrorMsg, shouldAwait) => async (dispatch) => {
    const axiosInstance = new ContainedAxiosFactory().getInstance();
    const actionsToDispatch = getDataServiceActionNames(payload.actionName);
    const hasCallback = payload && typeof payload.nextCallback === 'function';
    const hasErrorCallback = payload && typeof payload.errorCallback === 'function';
    const hasDispatch = typeof dispatch === 'function';
    let requestConfig = null;

    try {
      requestConfig = constructRequestConfig(payload);
    } catch (e) {
      // request was not constructed du to error
      const normalisedError = [handleResponseError(e), customErrorMsg].join('. \n');

      if (hasDispatch) {
        dispatch(
          action(
            OrchestrateAppActionTypes.SET_APPLICATION_EXCEPTION,
            [transformResponseError(e).message, customErrorMsg].join('. \n'),
            payload.meta
          )
        );

        return { statusCode: 500, message: normalisedError };
      } else {
        throw new Error(normalisedError);
      }
    }

    if (!requestConfig) {
      return {
        statusCode: 500,
        message: [MessageDictionary.ERROR_REQUEST_CONFIGURATION, customErrorMsg].join('. \n')
      };
    }

    // request was generated with success so dispatch start action
    if (hasDispatch) {
      dispatch(action(actionsToDispatch.start, payload, payload.meta));
    }

    // request success handler
    const onAxiosSuccess = (response) => {
      try {
        const forceJsonResponse =
          !isEmpty(requestConfig) && requestConfig.headers['Content-Type'].includes('application/json');
        let normalisedResponse = handleResponseSuccess(response, forceJsonResponse);

        // apply any response data transformation if provided
        if (typeof payload.dataTransformer === 'function') {
          normalisedResponse.data = payload.dataTransformer(normalisedResponse.data);
        }

        if (hasDispatch) {
          dispatch(action(actionsToDispatch.success, normalisedResponse, payload.meta));
        }

        // if a chained callback exists, invoke after success is dispatched on next tick to avoid race conditions
        if (hasCallback) {
          defer((response, meta) => payload.nextCallback(response, meta), normalisedResponse, payload.meta);
        }

        return normalisedResponse;
      } catch (e) {
        const normalisedError = [handleResponseError(e), customErrorMsg].join('. \n');
        const errorStackTrace = getResponseErrorStack(e);
        const transformedResponseError = transformResponseError(e, normalisedError);
        throwApplicationException(e, normalisedError, dispatch, requestConfig, errorStackTrace);

        if (hasDispatch) {
          dispatch(action(actionsToDispatch.error, transformedResponseError, payload.meta));
        } else {
          if (errorStackTrace) {
            e.stack = errorStackTrace;
          }

          if (e.message) {
            e.message = transformedResponseError.message;
          }

          throw e;
        }

        return response;
      }
    };

    const onAxiosError = (e, customErrorMsg) => {
      const normalisedError = [handleResponseError(e), customErrorMsg].join('. \n');
      const errorStackTrace = getResponseErrorStack(e);
      const transformedResponseError = transformResponseError(e, normalisedError);
      
      throwApplicationException(normalisedError, normalisedError, dispatch, requestConfig, errorStackTrace);

      if (hasErrorCallback) {
        payload.errorCallback(e.response.data);
      }

      if (hasDispatch) {
        dispatch(action(actionsToDispatch.error, transformedResponseError, payload.meta));
      } else {
        if (errorStackTrace) {
          e.stack = errorStackTrace;
        }

        if (e.message) {
          e.message = transformedResponseError.message;
        }

        throw e;
      }

      return transformedResponseError;
    };

    // Initiate axios request and get response in synchronous blocking manner if desired
    if (shouldAwait) {
      try {
        const apiResponse = await axiosInstance.request(requestConfig);
        return onAxiosSuccess(apiResponse);
      } catch (e) {
        return onAxiosError(e, customErrorMsg);
      }
    }

    // Else wise initiate Axios request in an asynchronous non blocking manner
    return axiosInstance.request(requestConfig).then(onAxiosSuccess, onAxiosError);
  };
}

export default DataService;
