import { ROBINPAY_YAPILY_SERVICE_ID } from '../../constants';
import { getServiceHostEndpoint } from '../../utilities/UrlDiscovery';
import { AccountsActionTypes } from './types';

const accountsEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_YAPILY_SERVICE_ID, '/bank-accounts'),
        method: 'get',
        data: {},
        headers: payload.headers,
        errorCallback: payload.errorCallback,
        mockData: {
          success: '/mocks/success/bank-accounts.json'
        }
      };
      break;

    default:
      break;
  }

  return endpointDetails;
};

export default accountsEndpointHandler;
