import assignIn from 'lodash/assignIn';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import RequestObjectFactory from './RequestObjectFactory';
import { getMockScenario } from '../MockData';
import { isLocalhost, isNotProductionMode } from '../Environment';
import { getSameHostEndpoint } from '../UrlDiscovery';
import ContainedAxiosFactory from './ContainedAxiosFactory';

class AxiosRequestObjectFactory extends RequestObjectFactory {
  static createRequestObject = (endpointDetails) => {
    const axiosInstance = new ContainedAxiosFactory().getInstance();
    const defaultRequestObject = RequestObjectFactory.getRequestObject();

    const hasPOSTData = endpointDetails.hasOwnProperty('data') && typeof endpointDetails.data !== 'undefined';

    // Instantiate Axios request object
    const requestObject = RequestObjectFactory.getRequestObject();
    requestObject.timeout = endpointDetails.timeout || requestObject.timeout;
    requestObject.method = endpointDetails.method || (hasPOSTData ? 'post' : 'get');
    requestObject.headers = assignIn(
      {},
      axiosInstance.defaults.headers.common,
      defaultRequestObject.headers,
      endpointDetails.headers
    );
    requestObject.params = assignIn({}, defaultRequestObject.params, endpointDetails.params);
    requestObject.withCredentials =
      typeof endpointDetails.withCredentials !== 'undefined'
        ? endpointDetails.withCredentials
        : defaultRequestObject.withCredentials;
    requestObject.transformResponse = [].concat(axiosInstance.defaults.transformResponse);

    // Stringify query string array params with repeat mode instead of default
    if (!isEmpty(requestObject.params) && endpointDetails.spreadRepeatArrayParams) {
      requestObject.paramsSerializer = (params) => qs.stringify(params, { arrayFormat: 'repeat' });
    }

    if (isEmpty(requestObject.params)) {
      delete requestObject.params;
    }

    // Attach the data to be the request
    if (hasPOSTData) {
      requestObject.data = endpointDetails.data;
    }

    // Attach specific type if any
    if (endpointDetails.responseType) {
      requestObject.responseType = endpointDetails.responseType;
    }

    // No transform is specifically requested text/plain as the axios default transform returns
    // JSON object from string response data
    if (requestObject.headers && requestObject.headers['Content-Type'].includes('plain/text')) {
      requestObject.transformResponse = [].concat((data) => data);
    }

    // Set the request Url to the one from endpoint configuration or the mock responses files
    let endpointUrl = endpointDetails.url || defaultRequestObject.url;
    let mockDataUrl = endpointDetails.mockData ? endpointDetails.mockData[getMockScenario()] : null;
    requestObject.url = endpointUrl;

    // Load the mocks for the local environment if they have been defined from query param mockScenario
    if (isNotProductionMode() && isLocalhost() && mockDataUrl) {
      requestObject.method = 'get';
      delete requestObject.params;
      requestObject.url = getSameHostEndpoint(mockDataUrl);
    }

    return requestObject;
  };
}

export default AxiosRequestObjectFactory;
