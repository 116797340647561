import React from 'react';
import { Chip } from '@material-ui/core';
import useStyles from './styles';

const states = {
  COMPLETED: 'success',
  COMPLETED_SETTLEMENT_IN_PROCESS: 'warning',
  PENDING: 'warning',
  ACTIVE: 'warning',
  FAILED: 'secondary',
  INACTIVE: 'secondary',
  UNKNOWN: 'secondary',
  EXPIRED: 'secondary',
  DECLINED: 'secondary'
};

const labels = {
  COMPLETED: 'COMPLETED',
  COMPLETED_SETTLEMENT_IN_PROCESS: 'SETTLEMENT IN PROCESS',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  FAILED: 'FAILED',
  INACTIVE: 'INACTIVE',
  UNKNOWN: 'UNKNOWN',
  EXPIRED: 'EXPIRED',
  DECLINED: 'DECLINED'
};

const StatusCustomBodyRender = (props) => {
  const classes = useStyles();
  const label = labels[props.status.toUpperCase()] || props.status;

  return <Chip label={label} classes={{ root: classes[states[props.status.toUpperCase()]] }} />;
};

export default StatusCustomBodyRender;
