import React from 'react';

function validateEmail(value) {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    value
  );
}

function validatePhone(value) {
  return /^21.\d{7}|^69.\d{7}/.test(value);
}

function validatePassword(value) {
  return /^.{5,20}$/.test(value);
}

export default function useValidation(validationSchemaValues, externalValidators) {
  const [serverValidationErrors, setServerValidationErrors] = React.useState({});
  const [clientValidationErrors, setClientValidationErrors] = React.useState({});

  const [validationMessages, setValidationMessages] = React.useState({});

  const uiErrors = React.useMemo(() => {
    return !!Object.keys(clientValidationErrors).length;
  }, [clientValidationErrors]);

  const serverErrors = React.useMemo(() => {
    return !!Object.keys(serverValidationErrors).length;
  }, [serverValidationErrors]);

  const validateValues = React.useCallback(
    (values) => {
      let isValidationError = false;
      validationSchemaValues.forEach((schemaValue) => {
        if (schemaValue === 'email' && !validateEmail(values[schemaValue])) {
          setClientValidationErrors((values) => ({ ...values, email: true }));
          setValidationMessages((values) => ({
            ...values,
            email: 'Please type a valid email'
          }));
          isValidationError = true;
          return;
        }

        if (schemaValue === 'phone' && !validatePhone(values[schemaValue])) {
          setClientValidationErrors((values) => ({ ...values, phone: true }));
          setValidationMessages((values) => ({
            ...values,
            phone: 'Phone must start with 210 or 69 and must be 10 digits!'
          }));
          isValidationError = true;
          return;
        }

        if (schemaValue === 'password' && !validatePassword(values[schemaValue])) {
          setClientValidationErrors((values) => ({
            ...values,
            password: true
          }));
          setValidationMessages((values) => ({
            ...values,
            password: 'Please type your password, from 5 up to 20 digits!'
          }));
          isValidationError = true;
          return;
        }

        if (!values[schemaValue]) {
          setClientValidationErrors((values) => ({
            ...values,
            [schemaValue]: true
          }));
          setValidationMessages((values) => ({
            ...values,
            [schemaValue]: 'The field is required!'
          }));
          isValidationError = true;
          return;
        }
      });

      return !isValidationError;
    },
    [setClientValidationErrors, validationSchemaValues]
  );

  const resetValidation = React.useCallback(() => {
    setClientValidationErrors({});
    setServerValidationErrors({});
  }, []);

  const onChange = (e, setter) => {
    if (!externalValidators[e.target.name]) {
      setter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      return;
    }

    const { isValid } = externalValidators[e.target.name];
    if (!isValid(e.target.value)) {
      setValidationMessages((prev) => ({
        ...prev,
        [e.target.name]: externalValidators[e.target.name].message
      }));
      setClientValidationErrors((prev) => ({ ...prev, [e.target.name]: true }));
      setter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
      return;
    }

    resetValidation();
    setter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const ifError = React.useCallback(
    (value) => {
      if (uiErrors) {
        return clientValidationErrors[value] ? true : false;
      }

      if (serverErrors) {
        return serverValidationErrors[value] ? true : false;
      }
    },
    [clientValidationErrors, serverValidationErrors, uiErrors, serverErrors]
  );

  const errorMessages = React.useCallback(
    (value, customMessage) => {
      if (uiErrors) {
        return clientValidationErrors[value] && customMessage ? customMessage : validationMessages[value];
      }

      if (serverErrors) {
        return serverValidationErrors[value] ? serverValidationErrors[value] : '';
      }
    },
    [clientValidationErrors, serverValidationErrors, uiErrors, serverErrors, validationMessages]
  );

  return {
    errorMessages,
    ifError,
    resetValidation,
    serverValidationErrors,
    setServerValidationErrors,
    validateValues,
    setValidationMessages,
    setClientValidationErrors,
    onChange
  };
}
