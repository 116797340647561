import { ROBINPAY_YAPILY_SERVICE_ID } from '../../constants';
import { getServiceHostEndpoint } from '../../utilities/UrlDiscovery';
import { TransactionsActionTypes } from './types';

const getPostBody = (payload) => {
  const postBody = {
    ...payload.data
  };

  if (postBody.merchantId === 0 || !postBody.merchantId) {
    delete postBody.merchantId;
  }

  if (postBody.storeId === 0 || !postBody.storeId) {
    delete postBody.storeId;
  }

  return postBody;
};

const transactionsEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_YAPILY_SERVICE_ID, '/transactions/all'),
        data: getPostBody(payload),
        headers: payload.headers,
        errorCallback: payload.errorCallback,
        mockData: {
          success: '/mocks/success/allTransactions.json'
        }
      };
      break;

    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_YAPILY_SERVICE_ID, '/transactions/all'),
        params: payload.params,
        method: 'GET',
        headers: payload.headers,
        mockData: {
          success: '/mocks/success/allTransactions.json'
        }
      };
      break;

    default:
      break;
  }

  return endpointDetails;
};

export default transactionsEndpointHandler;
