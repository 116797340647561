import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Grid, Box, Tabs, Tab, Button } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import useStyles from './styles';
import { getAwaitApiData } from '../../store/application/actions';
import { getDataFromDataService } from '../../utilities/DataService';
import { TransactionsActionTypes } from '../../store/transactions/types';
import PageTitle from '../../components/PageTitle/PageTitle';
import { transactionsColumnDefs, transactionsGridOptions } from './transactionsDataTableConfigs';
import { EmptyCard } from '../../components/EmptyCard';

function Transactions() {
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);
  const dispatch = useDispatch();
  const inboundTransactions = useSelector(
    (state) => state && state.transactionsState && state.transactionsState.inboundTransactions
  );
  const shouldFetchTransactions = useSelector(
    (state) => state && state.transactionsState && state.transactionsState.shouldFetchTransactions
  );
  const isLoading = useSelector(
    (state) => state && state.uiState && state.uiState.isLoading && state.uiState.isLoading.app
  );
  const errors = useSelector((state) => state && state.uiState && state.uiState.errors && state.uiState.errors.app);
  const outboundTransactions = useSelector(
    (state) => state && state.transactionsState && state.transactionsState.outboundTransactions
  );
  const currentMerchant = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentMerchant
  );
  const currentStore = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentStore
  );
  const currentCurrency = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentCurrency
  );

  const authToken = useSelector((state) => state && state.authState && state.authState.authToken);

  const onSetFetchTransactionsToFalse = useCallback(() => {
    dispatch({ type: TransactionsActionTypes.SET_SHOULD_FETCH_TRANSACTIONS, payload: false });
  }, [dispatch]);

  const onSetFetchTransactionsToTrue = useCallback(() => {
    dispatch({ type: TransactionsActionTypes.SET_SHOULD_FETCH_TRANSACTIONS, payload: true });
  }, [dispatch]);

  const getAllTransactions = useCallback(() => {
    const boundServiceFunction = (requestConfigPayload, customErrorMsg) =>
      dispatch(getAwaitApiData(requestConfigPayload, customErrorMsg));
    const requestTransactions = async () => {
      const payloadConfig = {
        payload: {
          actionName: TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS,
          data: {
            merchantId: currentMerchant,
            storeId: currentStore || null,
            currency: currentCurrency
          },
          nextCallback: onSetFetchTransactionsToFalse,
          errorCallback: onSetFetchTransactionsToFalse,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        },
        serviceFunction: boundServiceFunction
      };

      await getDataFromDataService(payloadConfig);
    };

    requestTransactions(dispatch);
  }, [dispatch, currentMerchant, currentStore, currentCurrency, authToken, onSetFetchTransactionsToFalse]);

  const onChangeTab = (e, id) => setActiveTabId(id);

  useEffect(() => {
    if (!isLoading && !errors && shouldFetchTransactions) {
      getAllTransactions();
    }
  }, [getAllTransactions, isLoading, errors, currentMerchant, currentStore, currentCurrency, shouldFetchTransactions]);

  return (
    <>
      <PageTitle
        title='Transactions'
        button={
          <Button
            onClick={onSetFetchTransactionsToTrue}
            variant='contained'
            size='small'
            color='secondary'
            endIcon={<RefreshIcon />}>
            Latest Transactions
          </Button>
        }
      />

      <Tabs value={activeTabId} onChange={onChangeTab} indicatorColor='secondary' textColor='secondary'>
        <Tab label='Inbound' classes={{ root: classes.tab }} />
        <Tab label='Outbound' classes={{ root: classes.tab }} />
      </Tabs>

      <Grid container spacing={4}>
        {activeTabId === 0 && (
          <Grid item xs={12}>
            <Box marginTop={2}>
              {!isLoading && inboundTransactions ? (
                <MUIDataTable
                  title='Inbound Transactions'
                  data={inboundTransactions}
                  columns={transactionsColumnDefs}
                  options={transactionsGridOptions('inbound')}
                />
              ) : (
                <EmptyCard isLoading={isLoading} content={{ title: 'transactions' }}>
                  Sorry, no matching inbound transactions records found
                </EmptyCard>
              )}
            </Box>
          </Grid>
        )}

        {activeTabId === 1 && (
          <Grid item xs={12}>
            <Box marginTop={2}>
              {!isLoading && outboundTransactions ? (
                <MUIDataTable
                  title='Outbound Transactions'
                  data={outboundTransactions}
                  columns={transactionsColumnDefs}
                  options={transactionsGridOptions('outbound')}
                />
              ) : (
                <EmptyCard isLoading={isLoading} content={{ title: 'transactions' }}>
                  Sorry, no matching outbound transactions records found
                </EmptyCard>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Transactions;
