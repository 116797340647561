import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../../pages/login/Login';
import Error from '../../pages/error/Error';
import { LayoutWithRouter } from '../Layout/Layout';

const PrivateRoutes = ({ isAuthenticated, entitlements, onLogout }) => {
  return (
    <Switch>
      <Route
        exact
        path='/'
        render={() => <Redirect to={{ pathname: '/app/overview', search: window.location.search }} />}
      />
      <Route
        exact
        path='/app'
        render={() => <Redirect to={{ pathname: '/app/overview', search: window.location.search }} />}
      />
      <Route
        exact
        path='/login'
        render={() => <Redirect to={{ pathname: '/app/overview', search: window.location.search }} />}
      />
      <Route
        path='/app'
        component={() => (
          <LayoutWithRouter isAuthenticated={isAuthenticated} entitlements={entitlements} onLogout={onLogout} />
        )}
      />
      <Route component={Error} />
    </Switch>
  );
};

const PublicRoutes = ({ isAuthenticated, onLogin, onUserCreate }) => {
  return (
    <Switch>
      <Route exact path='/' render={() => <Redirect to={{ pathname: '/login', search: window.location.search }} />} />
      <Route path='/app' render={() => <Redirect to={{ pathname: '/login', search: window.location.search }} />} />
      <Route
        path='/login'
        component={() => <Login isAuthenticated={isAuthenticated} onLogin={onLogin} onUserCreate={onUserCreate} />}
      />
      <Route component={Error} />
    </Switch>
  );
};

export { PublicRoutes, PrivateRoutes };
