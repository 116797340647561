const globalSettingsInitialState = {
  availableMerchants: [],
  availableStores: [],
  availableCurrencies: [{ id: 'GBP', label: 'GBP' }],
  currentMerchant: 0,
  currentStore: 0,
  currentCurrency: 'GBP'
};

export default globalSettingsInitialState;
