import transactionsInitialState from './initialState';
import { TransactionsActionTypes } from './types';
import { transactionsDataTransformer, transactionsDataFilterer } from '../../utilities/arrayTransformers/transactions';

const reducer = (state = transactionsInitialState, { type, payload, meta }) => {
  switch (type) {
    case TransactionsActionTypes.SET_SHOULD_FETCH_TRANSACTIONS:
      return { ...state, shouldFetchTransactions: payload };
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_OK:
      if (payload && payload.data) {
        return {
          ...state,
          shouldFetchTransactions: false,
          inboundTransactions: transactionsDataTransformer(transactionsDataFilterer(payload.data, 'INBOUND'), false),
          outboundTransactions: transactionsDataTransformer(transactionsDataFilterer(payload.data, 'OUTBOUND'), false)
        };
      }
      return {
        ...state,
        inboundTransactions: [],
        outboundTransactions: []
      };

    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_ERR:
      return {
        ...state,
        inboundTransactions: null,
        outboundTransactions: null
      };

    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE_OK:
      if (payload && payload.data) {
        return {
          ...state,
          inboundTransactions: transactionsDataTransformer(transactionsDataFilterer(payload.data, 'INBOUND'), false),
          outboundTransactions: transactionsDataTransformer(transactionsDataFilterer(payload.data, 'OUTBOUND'), false)
        };
      }
      return {
        ...state,
        inboundTransactions: [],
        outboundTransactions: []
      };

    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE_ERR:
      return {
        ...state,
        inboundTransactions: null,
        outboundTransactions: null
      };

    default:
      return state;
  }
};

export { reducer as transactionsReducer };
