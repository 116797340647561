import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
  Home as HomeIcon,
  Person as PersonIcon,
  SyncAlt as SyncAltIcon,
  // NotificationsNone as NotificationsIcon,
  // FormatSize as TypographyIcon,
  // FilterNone as UIElementsIcon,
  // BorderAll as TableIcon,
  // QuestionAnswer as SupportIcon,
  // LibraryBooks as LibraryIcon,
  // HelpOutline as FAQIcon,
  CropFree as QrIcon,
  ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';

// components
import SidebarLink from './components/SidebarLink/SidebarLink';
// import Dot from './components/Dot';

// context
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';
import { QrDialog } from '../QrDialog/QrDialog';

const structure = (onClick) => [
  { id: 0, label: 'Account Overview', link: '/app/overview', icon: <HomeIcon /> },
  { id: 1, label: 'Transactions', link: '/app/transactions', icon: <SyncAltIcon /> },
  { id: 2, label: 'Generate QR', onClick, icon: <QrIcon /> },
  // { id: 2, label: "Dashboard", link: "/app/dashboard", icon: <HomeIcon /> },
  // {
  //   id: 3,
  //   label: "Typography",
  //   link: "/app/typography",
  //   icon: <TypographyIcon />,
  // },
  // { id: 4, label: "Tables", link: "/app/tables", icon: <TableIcon /> },
  // {
  //   id: 5,
  //   label: "Notifications",
  //   link: "/app/notifications",
  //   icon: <NotificationsIcon />,
  // },
  // {
  //   id: 6,
  //   label: "UI Elements",
  //   link: "/app/ui",
  //   icon: <UIElementsIcon />,
  //   children: [
  //     { label: "Icons", link: "/app/ui/icons" },
  //     { label: "Charts", link: "/app/ui/charts" },
  //     { label: "Maps", link: "/app/ui/maps" },
  //   ],
  // },
  { id: 7, type: 'divider' },
  { id: 8, type: 'title', label: 'SETTINGS' },
  { id: 9, label: 'Profile', link: '/app/profile', icon: <PersonIcon /> }
  // { id: 10, label: "Support", link: "", icon: <SupportIcon /> },
  // { id: 11, label: "FAQ", link: "", icon: <FAQIcon /> },
  // { id: 12, type: "divider" },
  // { id: 13, type: "title", label: "PROJECTS" },
  // {
  //   id: 14,
  //   label: "My recent",
  //   link: "",
  //   icon: <Dot size="small" color="warning" />,
  // },
  // {
  //   id: 15,
  //   label: "Starred",
  //   link: "",
  //   icon: <Dot size="small" color="primary" />,
  // },
  // {
  //   id: 16,
  //   label: "Background",
  //   link: "",
  //   icon: <Dot size="small" color="secondary" />,
  // },
];

function Sidebar({ location }) {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();

  // global
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  // local
  const [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const onToggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <Drawer
        variant={isPermanent ? 'permanent' : 'temporary'}
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: isSidebarOpened,
            [classes.drawerClose]: !isSidebarOpened
          })
        }}
        open={isSidebarOpened}>
        <div className={classes.toolbar} />
        <div className={classes.mobileBackButton}>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <ArrowBackIcon
              classes={{
                root: classNames(classes.headerIcon, classes.headerIconCollapse)
              }}
            />
          </IconButton>
        </div>
        <List className={classes.sidebarList}>
          {structure(onToggleDrawer).map((link) => (
            <SidebarLink key={link.id} location={location} isSidebarOpened={isSidebarOpened} {...link} />
          ))}
        </List>
      </Drawer>
      <QrDialog isOpen={isOpen} onToggleDrawer={onToggleDrawer} />
    </>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
