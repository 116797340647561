import React, { useCallback, useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Slide,
  makeStyles,
  Box,
  CircularProgress,
  Button,
  TextField,
  Container,
  Typography,
  DialogActions,
  IconButton
} from '@material-ui/core';
import { useValidation } from '../../hooks';
import { useSelector } from 'react-redux';
import { AuthActionTypes } from '../../store/auth/types';
import { useDispatch } from 'react-redux';
import { PaymentsTypes } from '../../store/payments/types';
import { getAwaitApiData } from '../../store/application/actions';
import { getDataFromDataService } from '../../utilities/DataService';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentContainer: {
    margin: 'auto'
  },
  textFieldUnderline: {
    '&:before': {
      borderBottomColor: theme.palette.primary.light
    },
    '&:after': {
      borderBottomColor: theme.palette.primary.main
    },
    '&:hover:before': {
      borderBottomColor: `${theme.palette.primary.light} !important`
    }
  },
  textField: {
    borderBottomColor: theme.palette.background.light
  },
  loader: {
    marginLeft: theme.spacing(4)
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  img: {
    width: '200px',
    height: '200px',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(0)}px ${theme.spacing(6)}px`,
    alignItems: 'center'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const defaultValues = {
  invoiceId: '',
  amount: ''
};

const QrDialog = ({ isOpen, onToggleDrawer }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState(undefined);
  const [qrResponseValue, setQrResponseValue] = useState('');
  const [qrValues, setQrValues] = useState(defaultValues);

  const userDetails = useSelector(
    (state) => state && state.authState && state.authState.userDetails && state.authState.userDetails.userResponse
  );
  const currentCurrency = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentCurrency
  );
  const authToken = useSelector((state) => state && state.authState && state.authState.authToken);

  const externalValidators = {
    amount: {
      isValid: (value) => /^\d*?[.]?\d*$/.test(value),
      message: 'Amount must be a number, decimals are allowed. For decimal notation please use .'
    }
  };

  const { validateValues, errorMessages, ifError, onChange, setValidationMessages, setClientValidationErrors } =
    useValidation(['amount'], externalValidators);

  const onValidatorChangeHandler = (e) => {
    if (errors) {
      setErrors(undefined);
    }
    onChange(e, setQrValues, true, qrValues);
  };

  const isAmountError = ifError('amount');

  const amountHelperText = errorMessages('amount');

  const isGenerateQrButtonDisabled = qrValues.amount.length === 0 || isAmountError;

  const dispatch = useDispatch();

  const onSetFetchUserDetailsToTrue = useCallback(() => {
    dispatch({ type: AuthActionTypes.SET_SHOULD_FETCH_USER_DETAILS, payload: { data: true } });
  }, [dispatch]);

  useEffect(() => {
    if (!userDetails) {
      onSetFetchUserDetailsToTrue();
    }
  }, [userDetails, onSetFetchUserDetailsToTrue]);

  const onKeyPress = (e) => {
    const currentValue = e.target.value;

    if (e.key === '0' && currentValue === '0') {
      setValidationMessages((prev) => ({
        ...prev,
        [e.target.name]: 'After 0 please use . to denote decimal or provide another number'
      }));
      setClientValidationErrors((prev) => ({ ...prev, [e.target.name]: true }));
      e.target.value = 0;
      e.preventDefault();
      return;
    }

    const validFractionDigitPointer =
      /[.]/.test(e.key) &&
      !currentValue.includes('.') &&
      ((currentValue.includes('-') && currentValue.length > 1) ||
        (!currentValue.includes('-') && currentValue.length > 0));

    const validKey = /(\d|\w)/.test(e.key) || validFractionDigitPointer;

    if (!validKey) {
      setValidationMessages((prev) => ({
        ...prev,
        [e.target.name]: 'Only . is allowed following a number to denote decimal amount'
      }));
      setClientValidationErrors((prev) => ({ ...prev, [e.target.name]: true }));
      e.preventDefault();
    }
  };

  const onToggleDrawerWrapper = () => {
    if (qrResponseValue) {
      setQrResponseValue('');
    }
    if (errors) {
      setErrors(undefined);
    }
    if (isLoading) {
      setIsLoading(false);
    }
    setQrValues(defaultValues);
    setClientValidationErrors({});
    setValidationMessages({});
    onToggleDrawer();
  };

  const successHandler = (response) => {
    setIsLoading(false);
    setQrResponseValue(response.data.qrCode);
    setQrValues(defaultValues);
  };

  const errorHandler = (error) => {
    setIsLoading(false);
    const errors = [];
    if (error?.description) {
      const errorEntries = Object.entries(error.description);
      for (const [key, value] of errorEntries) {
        errors.push(`${key}: ${value[0]}`);
      }
    }
    setErrors({ code: error?.code, descriptions: errors });
  };

  const getQrPayment = useCallback(() => {
    const boundServiceFunction = (requestConfigPayload, customErrorMsg) =>
      dispatch(getAwaitApiData(requestConfigPayload, customErrorMsg));
    setIsLoading(true);
    const requestPaymentQr = async () => {
      const payloadConfig = {
        payload: {
          actionName: PaymentsTypes.REQUEST_PAYMENTS,
          data: {
            currency: currentCurrency,
            ...qrValues,
            recipientId: userDetails?.id
          },
          nextCallback: successHandler,
          errorCallback: errorHandler,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        },
        serviceFunction: boundServiceFunction
      };

      await getDataFromDataService(payloadConfig);
    };

    requestPaymentQr(dispatch);
  }, [dispatch, currentCurrency, authToken, qrValues, userDetails]);

  const onGenerateQRcode = () => {
    if (qrValues.amount === '0') {
      setValidationMessages((prev) => ({
        ...prev,
        amount: 'Zero amount is not accepted. Please provide another amount.'
      }));
      setClientValidationErrors((prev) => ({ ...prev, amount: true }));
      return;
    }
    if (!validateValues(qrValues)) {
      return;
    }
    getQrPayment();
  };

  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={onToggleDrawerWrapper} TransitionComponent={Transition}>
      <Box className={classes.dialogTitle}>
        <Typography variant='h6'>{!qrResponseValue ? 'Generate QR' : 'Generated QR'}</Typography>
        <IconButton onClick={onToggleDrawerWrapper}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent className={classes.dialogContent}>
        <Container maxWidth='xs' className={classes.contentContainer}>
          {errors && (
            <Box display='flex' justifyContent='center' mb={1}>
              <Alert severity='error'>
                <AlertTitle>{errors?.code}</AlertTitle>
                {errors?.descriptions.map((error) => (
                  <Box>{error}</Box>
                ))}
              </Alert>
            </Box>
          )}
          {!qrResponseValue ? (
            <>
              <TextField
                id='amount'
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                name='amount'
                onKeyPress={onKeyPress}
                value={qrValues.amount}
                onChange={onValidatorChangeHandler}
                margin='normal'
                placeholder='Amount'
                type='text'
                error={isAmountError}
                fullWidth
                helperText={amountHelperText}
              />
              <TextField
                id='invoiceId'
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField
                  }
                }}
                name='invoiceId'
                value={qrValues.invoiceId}
                onChange={onValidatorChangeHandler}
                margin='normal'
                placeholder='Invoice Id (Optional)'
                type='text'
                fullWidth
              />
              <DialogActions className={classes.formButtons}>
                <Button onClick={onToggleDrawerWrapper} size='small' color='secondary' disabled={isLoading}>
                  Cancel
                </Button>
                {isLoading ? (
                  <CircularProgress size={15} className={classes.loader} />
                ) : (
                  <Button disabled={isGenerateQrButtonDisabled} onClick={onGenerateQRcode} size='small' color='primary'>
                    Generate
                  </Button>
                )}
              </DialogActions>
            </>
          ) : (
            <>
              <Box display='flex' justifyContent='center'>
                <img
                  id='generatedQrCode'
                  src={qrResponseValue}
                  alt={`Generated Qr png img for ${userDetails.id}`}
                  className={classes.img}
                />
              </Box>
              <DialogActions className={classes.formButtons}>
                <Button onClick={onToggleDrawerWrapper} size='small' color='secondary' disabled={isLoading}>
                  Close
                </Button>
                <Button
                  component='a'
                  size='small'
                  color='primary'
                  download={`${userDetails.id}.png`}
                  href={qrResponseValue}>
                  DOWNLOAD QR
                </Button>
              </DialogActions>
            </>
          )}
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export { QrDialog };
