import { getSameHostEndpoint } from '../../utilities/UrlDiscovery';
import { OrchestrateAppActionTypes } from './types';

const applicationEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    case OrchestrateAppActionTypes.REQUEST_SERVICE_DISCOVERY:
      endpointDetails = {
        url: getSameHostEndpoint('/discovery.service.json')
      };
      return endpointDetails;

    default:
      return endpointDetails;
  }
};

export default applicationEndpointHandler;
