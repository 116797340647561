import { action } from 'typesafe-actions';
import { MessageDictionary } from './MessageDictionary';
import { OrchestrateAppActionTypes } from '../store/application/types';

/**
 * Takes a generic javascript exception and transforms it to an exception object
 *
 * @param {any} error
 * @param {string | null} [errorMessage]
 *
 * @returns IExceptionContext
 */
const transformException = (error, errorMessage) => {
  return {
    stack: error && error.stack ? error.stack : '',
    message: errorMessage ? errorMessage : error && error.message ? error.message : error
  };
};

/**
 * Dispatch application exception action
 *
 * @param {string | null} context
 * @param {any} error
 * @param {string | null} [errorMessage]
 * @param {Dispatch} [dispatch]
 * @param {string | null} [stackTrace]
 *
 * @returns Action | void
 */
const throwApplicationException = (context, error, errorMessage, dispatch, stackTrace) => {
  const exception = transformException(error, errorMessage);

  // The stacktrace if available is not displayed anywhere, thus omitted from state store
  return setApplicationException(context, exception.message, dispatch);
};

/**
 * Dispatch clear exception action
 *
 * @param {string} context
 * @param {Dispatch} [dispatch]
 *
 * @returns Action | void
 */
const clearApplicationException = (context, dispatch) => {
  const exceptionContext = context ? context : 'app';
  const actionMethod = action(OrchestrateAppActionTypes.CLEAR_APPLICATION_EXCEPTION, { context: exceptionContext });

  if (dispatch) {
    dispatch(actionMethod);
  }

  return actionMethod;
};

/**
 * Dispatch set exception action
 *
 * @param {string | null | undefined} context
 * @param {string | null} errorMessage
 * @param {Dispatch} [dispatch]
 *
 * @returns Action | void
 */
const setApplicationException = (context, errorMessage, dispatch) => {
  const exceptionContext = context ? context : 'app';
  const actionMethod = action(OrchestrateAppActionTypes.SET_APPLICATION_EXCEPTION, {
    context: exceptionContext,
    exception: transformException(null, errorMessage)
  });

  if (typeof dispatch === 'function') {
    dispatch(actionMethod);
    return;
  }

  return actionMethod;
};

/**
 * Transform the arguments of unhandled error into handleWindowException event argument
 *
 * @param {string | Event} event
 * @param {string} [source]
 * @param {number} [lineno]
 * @param {number} [colno]
 * @param {object} [error]
 *
 * @returns object
 */
const errorToEvent = (event, source, lineno, colno, error) => {
  if (typeof event === 'string') {
    return {
      message: event,
      source: source,
      lineno: lineno,
      colno: colno,
      error: error
    };
  }

  return event;
};

/**
 * Dispatch application refresh token action
 *
 * @param {any} error
 * @param {Dispatch} [dispatch]
 *
 * @returns Promise<Action | void>
 */
const throwRefreshTokenException = (error, dispatch) => {
  const actionMethod = action(OrchestrateAppActionTypes.SET_REFRESH_TOKEN_EXCEPTION, {
    exception: transformException(error, MessageDictionary.AUTH_TOKEN_EXPIRED)
  });

  if (dispatch) {
    dispatch(actionMethod);
    return;
  }

  return actionMethod;
};

export {
  transformException,
  throwApplicationException,
  clearApplicationException,
  errorToEvent,
  throwRefreshTokenException,
  setApplicationException
};
