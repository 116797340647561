import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppOrchestrator from './AppOrchestrator';
import { getAwaitApiData, setIsInstantiated } from '../../store/application/actions';
import {
  clearIsAuthenticated,
  setIsAuthenticated,
  setRefreshedTokens,
  onLogout,
  setAuthState,
  setShouldFetchUserDetails
} from '../../store/auth';
import { clearApplicationException, throwApplicationException } from '../../utilities/ErrorHandling';
import {
  setAvailableMerchants,
  setAvailableStores,
  setCurrentMerchant,
  setCurrentStore
} from '../../store/globalSettings';

const mapStateToProps = ({ uiState, authState, applicationState }, ownProps) => {
  return {
    authToken: authState && authState.authToken,
    refreshToken: authState && authState.refreshToken,
    userDetails: authState && authState.userDetails,
    entitlements: authState && authState.entitlements,
    serviceDiscovery: authState && authState.serviceDiscovery,
    isAuthenticated: authState && authState.isAuthenticated,
    isLoading: uiState && uiState.isLoading.app,
    errors: uiState && uiState.errors.app,
    isInstantiated: applicationState && applicationState.isInstantiated,
    shouldFetchUserDetails: authState && authState.shouldFetchUserDetails
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setIsInstantiated: setIsInstantiated,
      setIsAuthenticated: setIsAuthenticated,
      clearIsAuthenticated: clearIsAuthenticated,
      getAwaitAPIData: getAwaitApiData,
      throwApplicationException: throwApplicationException,
      clearApplicationException: clearApplicationException,
      setAvailableMerchants: setAvailableMerchants,
      setAvailableStores: setAvailableStores,
      setCurrentMerchant: setCurrentMerchant,
      setCurrentStore: setCurrentStore,
      setRefreshedTokens: setRefreshedTokens,
      onLogout: onLogout,
      setAuthState: setAuthState,
      setShouldFetchUserDetails: setShouldFetchUserDetails
    },
    dispatch
  )
});

const AppOrchestratorContainer = connect(mapStateToProps, mapDispatchToProps)(AppOrchestrator);

export default AppOrchestratorContainer;
