import React from 'react';
import { makeStyles, Card, CardContent, CardMedia, Avatar, Typography, Box } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  text: {
    margin: theme.spacing(0, 0, 0.5)
  },
  avatar: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(0.5)
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: theme.spacing(2, 2, 0)
  },
  card: {
    minWidth: '100%',
    backgroundColor: theme.palette.background.card
  },
  cardContent: {
    padding: theme.spacing(3, 0)
  }
}));

export default function MerchantCard(props) {
  const classes = useStyles();

  const { address, companyName, companyNumber, poCode, avatarImg } = { ...props.merchantDetails };

  return (
    <Card variant='outlined' className={classes.card} style={{ display: 'inline-block' }}>
      <CardMedia align='center'>
        <Avatar alt={companyName} src={avatarImg} className={classes.large} />
      </CardMedia>

      <CardContent className={classes.cardContent}>
        <Typography className={classes.text} color='textSecondary' variant='h5' align='center'>
          {companyName}
        </Typography>

        <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <LocationOnIcon className={classes.avatar} fontSize='small' />
          {address}
        </Typography>

        <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <MailOutlineIcon className={classes.avatar} fontSize='small' /> {poCode}
        </Typography>

        <Box marginTop={4}></Box>

        <Typography className={classes.text} color='textSecondary' variant='subtitle1' align='center'>
          <InfoIcon className={classes.avatar} fontSize='small' /> Company No: {companyNumber}
        </Typography>
      </CardContent>
    </Card>
  );
}
