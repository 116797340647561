import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
// import { withCookies } from 'react-cookie';
import themes, { overrides } from '../themes';
import AppOrchestratorContainer from './AppOrchestrator/AppOrchestratorContainer';
import ErrorBoundaryContainer from './ErrorBoundaryWrapper/ErrorBoundaryContainer';

const theme = createMuiTheme({ ...themes.default, ...overrides });

const App = (props) => (
  <MuiThemeProvider theme={theme}>
    <ErrorBoundaryContainer isRoot={true}>
      <AppOrchestratorContainer cookies={props.cookies} />
    </ErrorBoundaryContainer>
  </MuiThemeProvider>
);

export default App;
