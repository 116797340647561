import { isEmpty } from 'lodash';

const setAuthTokenInStorage = (payload = {}, storage = sessionStorage) => {
  if (!payload || isEmpty(payload.data)) {
    return;
  }
  storage.setItem('authToken', payload.data.access_token);
  storage.setItem('refreshToken', payload.data.refresh_token);
  if (payload.data.token_type) {
    storage.setItem('authTokenType', payload.data.token_type);
  }
};

const setUserDetailsInStorage = (payload = {}, storage = sessionStorage) => {
  if (!payload || isEmpty(payload.data)) {
    return;
  }
  storage.setItem('userDetails', JSON.stringify(payload.data));
  storage.setItem('isMerchant', !isEmpty(payload.data.merchantResponse));
  storage.setItem('isAdmin', !isEmpty(payload.data.adminResponse));
};

const clearStorageByProps = (propsToRemove = [], storage = sessionStorage) => {
  if (!propsToRemove || isEmpty(propsToRemove)) {
    return;
  }
  propsToRemove.forEach((prop) => storage.removeItem(prop));
};

const getItemsInStorageByProps = (props = [], storage = sessionStorage) => {
  if (!props || isEmpty(props)) {
    return;
  }

  const propsToReturn = {};

  props.forEach((prop) => {
    if (prop === 'userDetails' || prop === 'isMerchant' || prop === 'isAdmin') {
      propsToReturn[prop] = JSON.parse(storage.getItem(prop));
    } else {
      propsToReturn[prop] = storage.getItem(prop);
    }
  });

  return propsToReturn;
};

export { setAuthTokenInStorage, setUserDetailsInStorage, clearStorageByProps, getItemsInStorageByProps };
