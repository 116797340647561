import EndpointHandlerFactory from '../utilities/factories/EndpointHandlerFactory';
import applicationEndpointHandler from './application/endpoints';
import authEndpointHandler from './auth/endpoints';
import transactionsEndpointHandler from './transactions/endpoints';
import globalSettingsEndpointHandler from './globalSettings/endpoints';
import accountsEndpointHandler from './accounts/endpoints';
import { paymentsEndpointHandler } from './payments';

export const endpointHandlers = [
  applicationEndpointHandler,
  authEndpointHandler,
  transactionsEndpointHandler,
  globalSettingsEndpointHandler,
  accountsEndpointHandler,
  paymentsEndpointHandler
];

const configureEndpointHandlers = () => new EndpointHandlerFactory(endpointHandlers).getHandlers();

export default configureEndpointHandlers;
