import { ROBINPAY_CORE_SERVICE_ID, ROBINPAY_YAPILY_SERVICE_ID } from '../../constants';
import { getServiceHostEndpoint } from '../../utilities/UrlDiscovery';
import { AuthActionTypes } from './types';
import { jsonToFormSerialize } from '../../utilities/DataService';

const authEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    case AuthActionTypes.REQUEST_USER_LOGIN:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_CORE_SERVICE_ID, '/oauth/token'),
        data: jsonToFormSerialize(payload.data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        mockData: {
          success: '/mocks/success/login.json',
          loginOnly: '/mocks/success/login.json'
        }
      };
      break;

    case AuthActionTypes.REQUEST_USER_CREATE:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_CORE_SERVICE_ID, '/users'),
        data: payload.data,
        mockData: {
          success: '/mocks/success/create_user.json'
        }
      };
      break;

    case AuthActionTypes.REQUEST_USER_DETAILS:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_YAPILY_SERVICE_ID, '/users/details'),
        method: 'get',
        errorCallback: payload.errorCallback,
        headers: payload.headers,
        data: {},
        mockData: {
          success: '/mocks/success/users/details.json',
          userDetailsOnly: '/mocks/success/users/details.json'
        }
      };
      break;

    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_CORE_SERVICE_ID, '/api/userEntitlements'),
        mockData: {
          success: '/mocks/success/userEntitlements.json',
          userEntitlementsOnly: '/mocks/success/userEntitlements.json'
        }
      };
      break;

    default:
      break;
  }

  return endpointDetails;
};

export default authEndpointHandler;
