import moment from 'moment';

const transactionsDataTransformer = (data, isChartTransformer = true) => {
  const transactionData = data.map((transaction) => {
    const momentDate = moment.utc(transaction.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const formattedDateParts = momentDate.split('/');
    const fullDateForComparison = Number(formattedDateParts[2] + formattedDateParts[1] + formattedDateParts[0]);

    if (isChartTransformer) {
      return {
        id: transaction.id,
        fullDate: momentDate,
        amount: transaction.amount.amount,
        fullDateForComparison,
        currency: transaction.amount.currency
      };
    }

    return {
      ...transaction,
      currency: transaction.amount.currency
    };
  });
  return transactionData;
};

const transactionsDataFilterer = (data, direction) => data.filter((transaction) => transaction.direction === direction);

export { transactionsDataTransformer, transactionsDataFilterer };
