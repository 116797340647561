import applicationInitialState from './application/initialState';
import authInitialState from './auth/initialState';
import uiInitialState from './ui/initialState';
import transactionsInitialState from './transactions/initialState';
import globalSettingsInitialState from './globalSettings/initialState';
import accountsInitialState from './accounts/initialState';

const initialState = {
  applicationState: applicationInitialState,
  authState: authInitialState,
  uiState: uiInitialState,
  transactionsState: transactionsInitialState,
  globalSettingsState: globalSettingsInitialState,
  accountsState: accountsInitialState
};

export default initialState;
