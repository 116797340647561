import { Box, Button, CircularProgress, Fade, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useUserDispatch, loginUser } from '../../../context/UserContext';
import { useValidation } from '../../../hooks';
import useStyles from '../styles';

export default function LoginForm({ history, onLogin, isLoading, setIsLoading, error, setError }) {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const [loginValues, setLoginValues] = useState({
    email: '',
    password: ''
  });

  const { validateValues, errorMessages, ifError, resetValidation } = useValidation(['email', 'password']);

  const isEmailError = ifError('email');
  const isPasswordError = ifError('password');

  const emailHelperText = errorMessages('email', 'Please type a valid email');
  const passwordHelperText = errorMessages(
    'password',
    'Please type a valid password, from 5 up to 20 characters, digits or special characters'
  );

  const isLoginBtnDisabled =
    loginValues.email.length === 0 || loginValues.password.length === 0 || isEmailError || isPasswordError;

  const onChange = (e) => {
    resetValidation();
    setLoginValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onClickLogin = () => {
    if (!validateValues(loginValues)) {
      return;
    }
    loginUser(userDispatch, loginValues.email, loginValues.password, history, setIsLoading, setError, onLogin);
  };

  return (
    <React.Fragment>
      <Typography variant='h1' className={classes.greeting}>
        Welcome!
      </Typography>
      <Typography variant='h6' className={classes.greeting}>
        Sign in with your login details
      </Typography>
      <Fade in={!!error}>
        <Typography color='secondary' className={classes.errorMessage}>
          Something is wrong with your login or password, {error}
        </Typography>
      </Fade>
      <TextField
        id='email'
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField
          }
        }}
        name='email'
        value={loginValues.email}
        onChange={onChange}
        margin='normal'
        placeholder='Email Address'
        type='email'
        error={isEmailError}
        fullWidth
        helperText={emailHelperText}
      />
      <TextField
        id='password'
        InputProps={{
          classes: {
            underline: classes.textFieldUnderline,
            input: classes.textField
          }
        }}
        name='password'
        value={loginValues.password}
        error={isPasswordError}
        onChange={onChange}
        margin='normal'
        placeholder='Password'
        type='password'
        fullWidth
        helperText={passwordHelperText}
      />
      <Box className={classes.formButtons}>
        {isLoading ? (
          <CircularProgress size={26} className={classes.loginLoader} />
        ) : (
          <Button
            disabled={isLoginBtnDisabled}
            onClick={onClickLogin}
            size='large'
            variant='contained'
            color='primary'
            fullWidth>
            Log into my account
          </Button>
        )}
        {/* <Button color='primary' size='large' className={classes.forgetButton}>
      Forget Password
    </Button> */}
      </Box>
    </React.Fragment>
  );
}
