// eslint-disable-next-line// eslint-ignore
import { action } from 'typesafe-actions';
import AuthTokenFactory from '../../utilities/factories/AuthTokenFactory';
import { clearStorageByProps } from '../../utilities/storage';
import { AuthActionTypes } from './types';

const setIsAuthenticated = () => {
  return action(AuthActionTypes.SET_IS_AUTHENTICATED);
};

const clearIsAuthenticated = () => {
  return action(AuthActionTypes.CLEAR_IS_AUTHENTICATED);
};

const setAuthState = (payload) => {
  return action(AuthActionTypes.SET_AUTH_STATE, payload);
};

const setRefreshedTokens = (tokens) => {
  return action(AuthActionTypes.SET_REFRESHED_TOKENS, { data: tokens });
};

const setShouldFetchUserDetails = (value) => {
  return action(AuthActionTypes.SET_SHOULD_FETCH_USER_DETAILS, { data: value });
};

const onLogout = () => {
  const authTokenFactory = new AuthTokenFactory();
  clearStorageByProps(['authToken', 'refreshToken', 'authTokenType', 'userDetails', 'isMerchant', 'isAdmin']);
  authTokenFactory.removeFromAxiosDefaults();
  return action(AuthActionTypes.ON_LOGOUT);
};

export {
  setIsAuthenticated,
  clearIsAuthenticated,
  setRefreshedTokens,
  onLogout,
  setAuthState,
  setShouldFetchUserDetails
};
