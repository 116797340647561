import React from 'react';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM/YYYY';

const DateCustomBodyRender = (props) => {
  const formattedDate = moment.utc(props.date, 'YYYY-MM-DD').format(DATE_FORMAT);

  return <span>{formattedDate}</span>;
};

export default DateCustomBodyRender;
