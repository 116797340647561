import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from './configureReducer';
import { isNotProductionMode } from '../utilities/Environment';

export const history = createBrowserHistory();

const configureStore = (initialState) => {
  const composeEnhancers = composeWithDevTools({
    name: 'RobinPay Dashboard',
    actionsBlacklist: ['REDUX_STORAGE_SAVE']
  });

  const devEnhancers = composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, logger));

  const enhancers = composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware));

  return createStore(createRootReducer, initialState, isNotProductionMode() ? devEnhancers : enhancers);
};

export default configureStore;
