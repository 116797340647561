import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  backdrop: {
    width: '100%',
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: '#FFF',
    opacity: '0.5',
    zIndex: '1901'
  },
  centerChild: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: '1902'
  }
}));
