import qs from 'querystring';
import { getServiceHostEndpoint } from './UrlDiscovery';
import { isInJestMode } from './Environment';
import {
  AUTH_TOKEN_REFRESH_INTERVAL,
  AUTH_TOKEN_REFRESH_PATH,
  ROBINPAY_CORE_SERVICE_REFRESH_TOKEN_GRAND_TYPE,
  ROBINPAY_CORE_SERVICE_CLIENT_ID
} from '../constants';

class AuthProvider {
  services;
  refreshTokenPayload;
  refreshIntervals;
  refreshIntervalTimeout;
  refreshTokenCallback;

  constructor(authenticatedServices) {
    this.refreshIntervalTimeout = AUTH_TOKEN_REFRESH_INTERVAL;
    this.services = authenticatedServices;
    this.refreshTokenPayload = {
      grant_type: ROBINPAY_CORE_SERVICE_REFRESH_TOKEN_GRAND_TYPE,
      refresh_token: '',
      client_id: ROBINPAY_CORE_SERVICE_CLIENT_ID
    };
    this.refreshIntervals = {};
    this.refreshTokenCallback = (data) => true;
  }

  setRefreshToken(refreshToken) {
    this.refreshTokenPayload.refresh_token = refreshToken;
  }

  setRefreshTokenCallback(callback) {
    this.refreshTokenCallback = callback;
  }

  setServices(authenticatedServices) {
    this.services = authenticatedServices;
  }

  authenticateCookieBased() {
    this.services.forEach((service) => {
      try {
        this.refreshCookie(getServiceHostEndpoint(service, AUTH_TOKEN_REFRESH_PATH, true));
      } catch (e) {
        console.error(e);
      }
    });

    return this.services.length;
  }

  refreshCookie(serviceHostEndpoint) {
    if (isInJestMode()) {
      return true;
    }

    const AuthCookieRefreshed = fetch(serviceHostEndpoint, {
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
      },
      credentials: 'include',
      body: qs.stringify(this.refreshTokenPayload)
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((responseData) => {
            console.log(
              `%c Cookie Based Authentication successful for ${serviceHostEndpoint}:`,
              'color: #4CAF50;',
              responseData
            );

            this.refreshTokenCallback(responseData);
            return true;
          });
        }

        throw new Error(`Status: ${response.status}`);
      })
      .catch((error) => {
        console.error(`Could not authenticate to ${serviceHostEndpoint}: ${error.message}`);
        return false;
      });

    return !!AuthCookieRefreshed;
  }

  setRefreshIntervals() {
    this.services.forEach((service) => {
      this.setRefreshInterval(service);
    });
  }

  setRefreshInterval(service) {
    if (!this.refreshIntervals[service]) {
      this.refreshIntervals[service] = setInterval(
        () => this.refreshCookie(getServiceHostEndpoint(service, AUTH_TOKEN_REFRESH_PATH, true)),
        this.refreshIntervalTimeout
      );
    }
  }

  clearRefreshInterval(service) {
    if (this.refreshIntervals[service]) {
      clearInterval(this.refreshIntervals[service]);
      this.refreshIntervals[service] = undefined;
    }
  }

  clearRefreshIntervals() {
    this.services.forEach((service) => {
      this.clearRefreshInterval(service);
    });
  }
}

export { AuthProvider };
