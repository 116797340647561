import toLower from 'lodash/toLower';
import includes from 'lodash/includes';

const EnvironmentTypes = Object.freeze({
  LOCAL: 'local',
  DEV: 'dev',
  TEST: 'test',
  PROD: 'prod'
});

/**
 * Detect if application is loaded as localhost
 *
 * @returns boolean
 */
const isLocalhost = () => {
  const hostname = toLower(window.location.hostname);
  return includes(hostname, 'localhost') || includes(hostname, '.local');
};

/**
 * Detect if application is loaded from Jest for testing
 *
 * @returns boolean
 */
const isInJestMode = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

/**
 * Detect if application is loaded from Cypress for testing
 *
 * @returns boolean
 */
const isInCypressMode = () => {
  return window.Cypress !== undefined;
};

/**
 * Detect if application is not loaded in production node env
 *
 * @returns boolean
 */
const isNotProductionMode = () => {
  return process.env.NODE_ENV !== 'production';
};

export { EnvironmentTypes, isLocalhost, isInJestMode, isInCypressMode, isNotProductionMode };
