import * as React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import WarningOutlined from '@material-ui/icons/WarningOutlined';
import isEmpty from 'lodash/isEmpty';
import { errorToEvent, transformException } from '../../utilities/ErrorHandling';
import { MessageDictionary } from '../../utilities/MessageDictionary';
import useStyles from './styles';

// See more on error boundaries at:
// https://reactjs.org/docs/error-boundaries.html#where-to-place-error-boundaries
class ErrorBoundary extends React.PureComponent {
  static defaultProps = {
    errorTransformer: (error) => (error && error.message ? error.message : error),
    context: 'app',
    exceptions: null,
    errors: null,
    alwaysShowChildren: true,
    alertClassName: '',
    errorMessage: '',
    actions: {
      throwApplicationException: () => true,
      clearApplicationException: () => true
    },
    dispatch: undefined,
    concealErrorFromUI: () => false,
    isRoot: false,
    classes: undefined
  };

  constructor(props) {
    super(props);

    this.state = {
      hasReactException: false
    };

    this.clearApplicationExceptionContext = this.clearApplicationExceptionContext.bind(this);
    this.handleNonReactError = this.handleNonReactError.bind(this);
    this.attachNonReactErrorHandlers = this.attachNonReactErrorHandlers.bind(this);
  }

  // Attach event listener for all stray exceptions not caught by React Error Boundaries (callbacks, event listeners, unhandled)
  // Get the environment from response headers, the UserName, Service Discovery and get entitlements in a synchronous way
  componentDidMount() {
    if (this.props.isRoot) {
      this.attachNonReactErrorHandlers();
    }
  }

  // React component error boundary
  // By React design, the error is caught and then rethrown as unhandled exceptions on development mode for debugging purposes
  componentDidCatch(error, errorInfo) {
    this.props.actions.throwApplicationException(this.props.context, transformException(error), null);
  }

  // Store the existence of exception in the state
  static getDerivedStateFromError(error) {
    return { hasReactException: true };
  }

  // Get the error details from the window error event object properties ignoring the errors that will be processed by
  // componentDidCatch within the ErrorBoundary.
  // Due to the by design rethrow of unhandled exceptions duplication of error handling is done on development mode
  // See: https://github.com/facebook/react/issues/10474
  handleNonReactError(event) {
    const error = event.error || event.reason || event;
    const isHandledByBoundary =
      error &&
      error.stack &&
      error.stack.indexOf('invokeGuardedCallbackDev') >= 0 &&
      error.stack.indexOf('Promise') < 0 &&
      error.stack.indexOf('invokeGuardedCallbackAndCatchFirstError') < 0;

    if (!isHandledByBoundary) {
      this.props.actions.throwApplicationException(transformException(error || event));
    }

    return true;
  }

  // Catch unhandled errors and unhandled Promise errors or rejections
  // https://developer.mozilla.org/en-US/docs/Web/API/GlobalEventHandlers/onerror
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onrejectionhandled
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onunhandledrejection
  attachNonReactErrorHandlers() {
    window.onerror = (event, source, lineno, colno, error) =>
      this.handleNonReactError(errorToEvent(event, source, lineno, colno, error));
    window.onunhandledrejection = this.handleNonReactError;
    window.onrejectionhandled = this.handleNonReactError;
  }

  clearApplicationExceptionContext() {
    return this.props.actions.clearApplicationException(this.props.context || 'app');
  }

  render() {
    const props = { ...this.props };
    const state = { ...this.state };
    const isErrorConcealed = !!(props.concealErrorFromUI && props.concealErrorFromUI());
    const exceptionFromContext = props.exceptions && props.context ? props.exceptions[props.context] : null;
    const errorsFromContext =
      props.errors && props.context ? props.errorTransformer(props.errors[props.context]) : null;
    const shouldShowAlert = !!(
      state.hasReactException ||
      exceptionFromContext ||
      errorsFromContext ||
      !isEmpty(props.errorMessage)
    );
    const shouldShowChildren = !state.hasReactException && props.alwaysShowChildren;
    const contextErrorMsg = errorsFromContext
      ? errorsFromContext
      : exceptionFromContext && exceptionFromContext.message
      ? exceptionFromContext.message
      : MessageDictionary.GENERIC_ERROR;
    const errorMessage = props.errorMessage ? props.errorMessage : contextErrorMsg;

    if (shouldShowAlert) {
      console.log('%c Error in UI:', 'color: #EC6060; font-weight: bold', errorMessage);
    }

    return (
      <React.Fragment>
        {shouldShowAlert && !isErrorConcealed && (
          <Alert
            className={props.classes.fixedTopAlert}
            icon={<WarningOutlined fontSize='inherit' />}
            severity={props.alertClassName || 'error'}
            onClose={this.clearApplicationExceptionContext}>
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {shouldShowChildren && props.children}
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ErrorBoundary);
