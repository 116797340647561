const uiInitialState = {
  isLoading: {
    app: false
  },
  errors: {
    app: null
  },
  exceptions: {
    app: null
  }
};

export default uiInitialState;
