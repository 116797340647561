/**
 * Define what the mock data key you want to load by passing the query string param key mockScenario
 *
 * @returns string
 */
const getMockScenario = () => {
  return getQueryParam('mockScenario') || 'noMockedDataByDefault';
};

/**
 * Get the value of a query string param by param key
 *
 * @param {string} paramKey
 *
 * @returns string
 */
const getQueryParam = (paramKey) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramKey) || null;
};

export { getMockScenario, getQueryParam };
