import globalSettingsInitialState from './initialState';
import { GlobalSettingsActionTypes } from './types';

const reducer = (state = globalSettingsInitialState, { type, payload, meta }) => {
  switch (type) {
    case GlobalSettingsActionTypes.SET_CURRENT_MERCHANT:
      return {
        ...state,
        currentMerchant: payload
      };

    case GlobalSettingsActionTypes.SET_CURRENT_STORE:
      return {
        ...state,
        currentStore: payload
      };

    case GlobalSettingsActionTypes.SET_CURRENT_CURRENCY:
      return {
        ...state,
        currentCurrency: payload
      };

    case GlobalSettingsActionTypes.SET_AVAILABLE_MERCHANTS:
      return {
        ...state,
        availableMerchants: payload
      };

    case GlobalSettingsActionTypes.SET_AVAILABLE_STORES:
      return {
        ...state,
        availableStores: payload
      };

    case GlobalSettingsActionTypes.SET_AVAILABLE_CURRENCIES:
      return {
        ...state,
        availableCurrencies: payload
      };

    default:
      return state;
  }
};

export { reducer as globalSettingsReducer };
