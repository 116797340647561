import moment from 'moment';

export default function useFilteringDates(filter, shouldReturnOnlyComparisonDate = false) {
  const currentMomentObject = moment();
  const endDate = moment();

  const endDateFormation = endDate.utc().format('DD/MM/YYYY');
  const formattedEndDayParts = endDateFormation.split('/');
  const endDateForComparison = Number(formattedEndDayParts[2] + formattedEndDayParts[1] + formattedEndDayParts[0]);

  switch (filter) {
    case 'year to date':
      const currentYear = currentMomentObject.year();
      currentMomentObject.year(currentYear - 1);
      if (shouldReturnOnlyComparisonDate) {
        const lastYearDate = currentMomentObject.utc().format('DD/MM/YYYY');
        const formattedDateParts = lastYearDate.split('/');
        const startDateForComparison = Number(formattedDateParts[2] + formattedDateParts[1] + formattedDateParts[0]);
        return { startDate: startDateForComparison, endDate: endDateForComparison };
      }
      return { startDate: currentMomentObject.utc().format(), endDate: endDate.utc().format() };
    case 'last 30 days':
      const currentMonth = currentMomentObject.month();
      currentMomentObject.month(currentMonth - 1);
      if (shouldReturnOnlyComparisonDate) {
        const last30DaysDate = currentMomentObject.utc().format('DD/MM/YYYY');
        const formattedDateParts = last30DaysDate.split('/');
        const startDateForComparison = Number(formattedDateParts[2] + formattedDateParts[1] + formattedDateParts[0]);
        return { startDate: startDateForComparison, endDate: endDateForComparison };
      }
      return { startDate: currentMomentObject.utc().format(), endDate: endDate.utc().format() };
    case 'daily':
      if (shouldReturnOnlyComparisonDate) {
        const currentDate = currentMomentObject.utc().format('DD/MM/YYYY');
        const formattedDateParts = currentDate.split('/');
        const startDateForComparison = Number(formattedDateParts[2] + formattedDateParts[1] + formattedDateParts[0]);
        return { startDate: startDateForComparison, endDate: endDateForComparison };
      }
      return { startDate: currentMomentObject.utc().format(), endDate: endDate.utc().format() };
    default:
      const currentDay = currentMomentObject.date();
      currentMomentObject.date(currentDay - 7);
      if (shouldReturnOnlyComparisonDate) {
        const last7DaysDate = currentMomentObject.utc().format('DD/MM/YYYY');
        const formattedDateParts = last7DaysDate.split('/');
        const startDateForComparison = Number(formattedDateParts[2] + formattedDateParts[1] + formattedDateParts[0]);
        return { startDate: startDateForComparison, endDate: endDateForComparison };
      }
      return { startDate: currentMomentObject.utc().format(), endDate: endDate.utc().format() };
  }
}
