import groupBy from 'lodash/groupBy';

const collectionGroupedByProp = (data, propToGroup, dataTransformerCb) => {
  if (dataTransformerCb) {
    const transformedData = dataTransformerCb(data);
    const groupedData = groupBy(transformedData, propToGroup);
    return Object.values(groupedData);
  }
  const groupedData = Object.values(groupBy(data, propToGroup));
  return groupedData;
};

const dataResolverForUseEffect = (callbackFunction, callbackFunctionProps, dataSetter) => {
  const resolvedData = callbackFunction(...callbackFunctionProps);
  dataSetter(resolvedData);
};

const memoizedDataSetter = (cache, prop, value) => {
  if (prop in cache) {
    cache[prop] = cache[prop] + value;
    return;
  }

  cache[prop] = value;
};

export { collectionGroupedByProp, dataResolverForUseEffect, memoizedDataSetter };
