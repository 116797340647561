import React, { useState, useCallback, useEffect } from 'react';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import { Grid, Box, Select, OutlinedInput, MenuItem, Button, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getAwaitApiData } from '../../store/application/actions';
import { getDataFromDataService } from '../../utilities/DataService';
import { TransactionsActionTypes } from '../../store/transactions/types';
import useStyles from './styles';
import Widget from '../../components/Widget/Widget';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Typography } from '../../components/Wrappers/Wrappers';
import Dot from '../../components/Sidebar/components/Dot';
import { TransactionsComposedChart, DailyTransactionsWidget } from './components';

export default function AccountOverview(props) {
  const [chartPeriod, setChartPeriod] = useState('last 7 days');
  const classes = useStyles();

  const dispatch = useDispatch();

  const inboundTransactions = useSelector(
    (state) => state && state.transactionsState && state.transactionsState.inboundTransactions
  );
  const shouldFetchTransactions = useSelector(
    (state) => state && state.transactionsState && state.transactionsState.shouldFetchTransactions
  );
  const isLoading = useSelector(
    (state) => state && state.uiState && state.uiState.isLoading && state.uiState.isLoading.app
  );
  const errors = useSelector((state) => state && state.uiState && state.uiState.errors && state.uiState.errors.app);
  const currentMerchant = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentMerchant
  );
  const currentStore = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentStore
  );
  const currentCurrency = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentCurrency
  );
  const authToken = useSelector((state) => state && state.authState && state.authState.authToken);

  const onSetFetchTransactionsToFalse = useCallback(() => {
    dispatch({ type: TransactionsActionTypes.SET_SHOULD_FETCH_TRANSACTIONS, payload: false });
  }, [dispatch]);

  const onSetFetchTransactionsToTrue = useCallback(() => {
    dispatch({ type: TransactionsActionTypes.SET_SHOULD_FETCH_TRANSACTIONS, payload: true });
  }, [dispatch]);

  const getAllTransactions = useCallback(() => {
    const boundServiceFunction = (requestConfigPayload, customErrorMsg) =>
      dispatch(getAwaitApiData(requestConfigPayload, customErrorMsg));

    const requestTransactions = async () => {
      const payloadConfig = {
        payload: {
          actionName: TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS,
          data: {
            currency: currentCurrency,
            merchantId: currentMerchant,
            storeId: currentStore || null
          },
          nextCallback: onSetFetchTransactionsToFalse,
          errorCallback: onSetFetchTransactionsToFalse,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        },
        serviceFunction: boundServiceFunction
      };

      await getDataFromDataService(payloadConfig);
    };

    requestTransactions(dispatch);
  }, [dispatch, currentCurrency, currentMerchant, currentStore, onSetFetchTransactionsToFalse, authToken]);

  useEffect(() => {
    if (!isLoading && !errors && shouldFetchTransactions) {
      getAllTransactions();
    }
  }, [
    getAllTransactions,
    isLoading,
    errors,
    currentMerchant,
    currentStore,
    currentCurrency,
    shouldFetchTransactions,
    authToken
  ]);

  // Callbacks
  const renderChartTitle = useCallback((chartPeriod) => {
    switch (chartPeriod) {
      case 'last 30 days':
        return 'Last 30 days chart';
      case 'year to date':
        return 'Year to date chart';
      default:
        return 'Last 7 days chart';
    }
  }, []);

  const onSelectPeriod = useCallback((e) => {
    setChartPeriod(e.target.value);
  }, []);

  return (
    <>
      <PageTitle
        title='Account Overview'
        button={
          <Button
            variant='contained'
            size='small'
            color='secondary'
            onClick={onSetFetchTransactionsToTrue}
            endIcon={<RefreshIcon />}>
            Latest Reports
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <Box className={classes.mainChartHeader}>
                <Typography variant='h5' color='text' colorBrightness='secondary'>
                  {renderChartTitle(chartPeriod)}
                </Typography>
                <Box className={classes.mainChartHeaderLabels}>
                  <Box className={classes.mainChartHeaderLabel}>
                    <Dot color='warning' />
                    <Typography className={classes.mainChartLegentElement}>Volume of transactions</Typography>
                  </Box>
                  <Box className={classes.mainChartHeaderLabel}>
                    <Dot color='primary' />
                    <Typography className={classes.mainChartLegentElement}>Value of transactions</Typography>
                  </Box>
                </Box>
                <Select
                  value={chartPeriod}
                  onChange={onSelectPeriod}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect
                      }}
                    />
                  }
                  autoWidth>
                  <MenuItem value='last 7 days'>Last 7 days</MenuItem>
                  <MenuItem value='last 30 days'>Last 30 days</MenuItem>
                  <MenuItem value='year to date'>Year to date</MenuItem>
                </Select>
              </Box>
            }>
            <TransactionsComposedChart data={inboundTransactions} chartPeriod={chartPeriod} isLoading={isLoading} />
          </Widget>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper>
            <DailyTransactionsWidget
              data={inboundTransactions}
              selectedCurrency={currentCurrency}
              isLoading={isLoading}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
