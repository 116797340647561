const GlobalSettingsActionTypes = Object.freeze({
  SET_CURRENT_MERCHANT: '@@GlobalSettings/SetCurrentMerchant',
  SET_CURRENT_STORE: '@@GlobalSettings/SetCurrentStore',
  SET_CURRENT_CURRENCY: '@@GlobalSettings/SetCurrentCurrency',
  SET_AVAILABLE_MERCHANTS: '@@GlobalSettings/SetAvailableMerchants',
  SET_AVAILABLE_STORES: '@@GlobalSettings/SetAvailableStores',
  SET_AVAILABLE_CURRENCIES: '@@GlobalSettings/SetAvailableCurrencies'
});

export { GlobalSettingsActionTypes };
