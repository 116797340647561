import isEmpty from 'lodash/isEmpty';

let endpointHandlerList;

class EndpointHandlerFactory {
  constructor(endpointHandlerItems) {
    if (endpointHandlerItems && !isEmpty(endpointHandlerItems)) {
      endpointHandlerList = endpointHandlerItems;
    }
  }

  getHandlers() {
    return endpointHandlerList;
  }
}

export default EndpointHandlerFactory;
