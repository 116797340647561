import { sortBy } from 'lodash';
import { transactionsDataTransformer } from '../arrayTransformers/transactions';
import { collectionGroupedByProp } from '../generic';

/* get max value for a domain via a prop */
const getDataForDomains = (data, keyProps) => {
  if (!data && !keyProps) {
    return [0, 0];
  }

  const numbers = [];

  for (const item of data) {
    let accessedProp;
    for (const prop of keyProps) {
      accessedProp = accessedProp ? accessedProp[prop] : item[prop];
    }
    numbers.push(accessedProp);
  }
  // get highest number
  const highest = Math.max(...numbers);

  // get lowest number
  const lowest = Math.min(...numbers);

  return [lowest, highest];
};

/* Transactions Data comparison filter by compareDate */
const fullDateComparisonPredicate =
  (startDate, endDate, selectedCurrency) =>
  ({ fullDateForComparison, currency }) => {
    if (selectedCurrency) {
      return fullDateForComparison >= startDate && fullDateForComparison <= endDate && currency === selectedCurrency;
    }
    return fullDateForComparison >= startDate && fullDateForComparison <= endDate;
  };
/* get Dashboards Chart Data */
const dashboardTransactionsChartDataNormalizer = (data, propToGroupBy = 'fullDate') => {
  if (!data) {
    return [];
  }

  const groupedDataByProp = collectionGroupedByProp(data, propToGroupBy, transactionsDataTransformer);

  const normalizedDataToTransactionsChartData = [];

  for (const item of groupedDataByProp) {
    if (Array.isArray(item)) {
      if (item.length === 1) {
        normalizedDataToTransactionsChartData.push({
          volumeOfTransactions: item.length * 1000,
          valueOfTransactions: item[0].amount,
          fullDateForComparison: item[0].fullDateForComparison,
          fullDate: item[0].fullDate,
          currency: item[0].currency
        });
      } else {
        for (const subItem of item) {
          const foundTransactionIndex = normalizedDataToTransactionsChartData.findIndex(
            (transaction) =>
              transaction.currency === subItem.currency &&
              transaction.fullDateForComparison === subItem.fullDateForComparison
          );
          if (foundTransactionIndex === -1) {
            normalizedDataToTransactionsChartData.push({
              volumeOfTransactions: 1000,
              valueOfTransactions: subItem.amount,
              fullDateForComparison: subItem.fullDateForComparison,
              fullDate: subItem.fullDate,
              currency: subItem.currency
            });
          } else {
            normalizedDataToTransactionsChartData[foundTransactionIndex] = {
              volumeOfTransactions:
                normalizedDataToTransactionsChartData[foundTransactionIndex].volumeOfTransactions + 1000,
              valueOfTransactions:
                normalizedDataToTransactionsChartData[foundTransactionIndex].valueOfTransactions + subItem.amount,
              fullDateForComparison: normalizedDataToTransactionsChartData[foundTransactionIndex].fullDateForComparison,
              fullDate: normalizedDataToTransactionsChartData[foundTransactionIndex].fullDate,
              currency: normalizedDataToTransactionsChartData[foundTransactionIndex].currency
            };
          }
        }
      }
    }
  }

  return sortBy(normalizedDataToTransactionsChartData, (o) => o.fullDateForComparison);
};

export { getDataForDomains, dashboardTransactionsChartDataNormalizer, fullDateComparisonPredicate };
