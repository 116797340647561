import accountsInitialState from './initialState';
import { AccountsActionTypes } from './types';

const reducer = (state = accountsInitialState, { type, payload, meta }) => {
  switch (type) {
    case AccountsActionTypes.SET_SHOULD_FETCH_ACCOUNTS:
      return {
        ...state,
        shouldFetchAccounts: payload
      };
      
    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS_OK:
      return {
        ...state,
        accounts: payload.data ? payload.data.bankAccounts : []
      };

    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS_ERR:
      return {
        ...state,
        accounts: null
      };

    default:
      return state;
  }
};

export { reducer as accountsReducer };
