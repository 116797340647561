const TransactionsActionTypes = Object.freeze({
  SET_SHOULD_FETCH_TRANSACTIONS: '@@/Transactions/SetShouldFetchTransactions',
  REQUEST_ALL_TRANSACTIONS: '@@Transactions/RequestAllTransactions',
  REQUEST_ALL_TRANSACTIONS_OK: '@@Transactions/RequestAllTransactionsOk',
  REQUEST_ALL_TRANSACTIONS_ERR: '@@Transactions/RequestAllTransactionsErr',
  REQUEST_ALL_TRANSACTIONS_BY_DATE: '@@Transactions/RequestAllTransactionsByDate',
  REQUEST_ALL_TRANSACTIONS_BY_DATE_OK: '@@Transactions/RequestAllTransactionsByDateOk',
  REQUEST_ALL_TRANSACTIONS_BY_DATE_ERR: '@@Transactions/RequestAllTransactionsByDateErr'
});

export { TransactionsActionTypes };
