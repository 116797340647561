import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import { AuthProvider } from '../../utilities/Auth';
import { OrchestrateAppActionTypes } from '../../store/application/types';
import { getDataFromDataService } from '../../utilities/DataService';
import ServiceDiscoveryFactory from '../../utilities/factories/ServiceDiscoveryFactory';
import {
  ALL_MERCHANTS_OPTION,
  ROBINPAY_CORE_SERVICE_CLIENT_ID,
  ROBINPAY_CORE_SERVICE_GRAND_TYPE,
  ROBINPAY_CORE_SERVICE_ID
} from '../../constants';
import { history } from '../../store/configureStore';
import { PublicRoutes, PrivateRoutes } from '../Routes/Routes';
import { AuthActionTypes } from '../../store/auth/types';
import Spinner from '../Spinner/Spinner';
import { MessageDictionary } from '../../utilities/MessageDictionary';
import AuthTokenFactory from '../../utilities/factories/AuthTokenFactory';
import { ALL_STORES_OPTION } from '../../constants/index';
import { getItemsInStorageByProps } from '../../utilities/storage';

class AppOrchestrator extends React.PureComponent {
  authProvider;

  static defaultProps = {
    cookies: null,
    userDetails: null,
    entitlements: null,
    className: '',
    cookieBasedAuthServices: [ROBINPAY_CORE_SERVICE_ID],
    isInstantiated: false,
    isAuthenticated: false,
    errors: null,
    isLoading: false,
    serviceDiscovery: [],
    actions: {
      setIsInstantiated: () => true,
      setIsAuthenticated: () => true,
      clearIsAuthenticated: () => true,
      getAwaitAPIData: () => true
    },
    transactionsInbound: [],
    transactionsOutbound: [],
    dispatch: undefined
  };

  constructor(props) {
    super(props);

    this.authProvider = new AuthProvider(props.cookieBasedAuthServices, {});

    this.loadMandatoryData = this.loadMandatoryData.bind(this);
    this.loadUserData = this.loadUserData.bind(this);
    this.requestServiceDiscovery = this.requestServiceDiscovery.bind(this);
    this.requestUserDetails = this.requestUserDetails.bind(this);
    this.requestEntitlements = this.requestEntitlements.bind(this);
    this.requestUserLoginWithCredentials = this.requestUserLoginWithCredentials.bind(this);
    this.requestUserCreate = this.requestUserCreate.bind(this);
    this.setGlobalControlsData = this.setGlobalControlsData.bind(this);
    this.setRefreshTokenCallback = this.setRefreshTokenCallback.bind(this);
    this.logoutUser = this.logoutUser.bind(this);
    this.setAuthState = this.setAuthState.bind(this);
  }

  // Get the environment from response headers, userDetails, Service Discovery and get entitlements
  async componentDidMount() {
    this.setAuthState();
    await this.loadMandatoryData();
  }

  // TODO: create logout functionality
  async componentDidUpdate() {
    const shouldLoadUserData =
      this.props.isInstantiated && this.props.isAuthenticated && !this.props.errors && !this.props.isLoading;

    if (shouldLoadUserData) {
      await this.loadUserData();
    }
  }

  // Detach auth cookie token refresh
  componentWillUnmount() {
    this.authProvider.clearRefreshIntervals();
  }

  // setAuthState when user has already logged in and is stored in localStorage
  setAuthState() {
    const items = getItemsInStorageByProps(['refreshToken', 'authTokenType', 'authToken']);
    const shouldSetAuthState = Boolean(
      items &&
        !isEmpty(items) &&
        items.authToken !== null &&
        items.refreshToken !== null &&
        items.authTokenType !== null
    );
    if (shouldSetAuthState) {
      this.props.actions.setAuthState({
        ...items,
        isAuthenticated: Boolean(items.authToken)
      });
    }
  }

  // Make the required requests to initialise the app with environment services
  async loadMandatoryData() {
    const environment = process.env.REACT_APP_ENVIRONMENT;
    const shouldGetServiceDiscovery = isEmpty(this.props.serviceDiscovery) && environment;

    if (shouldGetServiceDiscovery) {
      const serviceDiscoveryList = await this.requestServiceDiscovery(environment);

      if (serviceDiscoveryList && serviceDiscoveryList[environment]) {
        new ServiceDiscoveryFactory(serviceDiscoveryList[environment]);
        this.props.actions.setIsInstantiated();
      }
    }

    // Log the environment for auditing purposes
    console.log('%c Detected environment: ', 'color: #4CAF50; font-weight: bold', environment);
  }

  // Make the required requests to initialise the app with user details
  async loadUserData() {
    const shouldFetchUserDetails = this.props.shouldFetchUserDetails;
    const shouldGetUserDetails = !this.props.userDetails;
    const shouldGetEntitlements = !this.props.entitlements;

    if (shouldGetUserDetails && shouldFetchUserDetails) {
      await this.requestUserDetails();
    } else if (shouldGetEntitlements) {
      //await this.requestEntitlements();

      this.authProvider.setServices(this.props.cookieBasedAuthServices);
      this.authProvider.setRefreshToken(this.props.refreshToken);
      this.authProvider.setRefreshTokenCallback(this.setRefreshTokenCallback);
      //this.authProvider.authenticateCookieBased();
      this.authProvider.setRefreshIntervals();
    }
  }

  async logoutUser() {
    return this.props.actions.onLogout();
  }

  setRefreshTokenCallback(data) {
    new AuthTokenFactory(data.access_token, data.token_type, true);
    this.props.actions.setRefreshedTokens(data);
  }

  /**
   * Request the endpoint to get service configuration values in a blocking way
   *
   * @param {string} env
   *
   * @returns Promise<object[]>
   */
  async requestServiceDiscovery(env) {
    const payloadConfig = {
      payload: {
        actionName: OrchestrateAppActionTypes.REQUEST_SERVICE_DISCOVERY,
        params: { env: env }
      },
      serviceFunction: this.props.actions.getAwaitAPIData
    };
    const response = await getDataFromDataService(payloadConfig);
    return response ? response.data : response;
  }

  /**
   * Request the endpoint to initiate a credentials based authentication in a blocking way
   *
   * @returns Promise<object>
   */
  async requestUserLoginWithCredentials(username, password) {
    this.props.actions.clearApplicationException();

    const payloadConfig = {
      payload: {
        actionName: AuthActionTypes.REQUEST_USER_LOGIN,
        data: {
          client_id: ROBINPAY_CORE_SERVICE_CLIENT_ID,
          grant_type: ROBINPAY_CORE_SERVICE_GRAND_TYPE,
          username: username,
          password: password
        }
      },
      serviceFunction: this.props.actions.getAwaitAPIData
    };
    const userLoginResponse = await getDataFromDataService(payloadConfig);

    if (userLoginResponse && userLoginResponse.status === 200 && userLoginResponse.data.access_token) {
      new AuthTokenFactory(userLoginResponse.data.access_token, userLoginResponse.data.token_type, true);
      this.props.actions.setIsAuthenticated();

      return true;
    } else {
      this.props.actions.throwApplicationException(MessageDictionary.AUTHENTICATION_FAILED);
      return false;
    }
  }

  /**
   * Request the endpoint to create a new user in a blocking way
   *
   * @returns Promise<object>
   */
  async requestUserCreate(firstName, lastName, email, password) {
    this.props.actions.clearApplicationException();

    const payloadConfig = {
      payload: {
        actionName: AuthActionTypes.REQUEST_USER_CREATE,
        data: {
          firstName: firstName,
          lastName: lastName,
          displayName: `${lastName} ${firstName}`,
          email: email,
          password: password
        }
      },
      serviceFunction: this.props.actions.getAwaitAPIData
    };
    const userCreateResponse = await getDataFromDataService(payloadConfig);

    if (userCreateResponse && (userCreateResponse.status === 200 || userCreateResponse.status === 201)) {
      this.props.actions.throwApplicationException(`${MessageDictionary.USER_CREATION_SUCCESS}`);
      return true;
    } else {
      this.props.actions.throwApplicationException(
        `${MessageDictionary.USER_CREATION_FAILED}.\n${userCreateResponse && userCreateResponse.error_description}`
      );

      return false;
    }
  }

  /**
   * Set the global controls data in redux store as post data retrieval step of requestUserDetails
   *
   */
  setGlobalControlsData(response) {
    const nextAvailableMerchants =
      response.data && response.data.adminResponse ? response.data.adminResponse.merchantList : [];
    const nextAvailableStores =
      response.data && response.data.merchantResponse ? response.data.merchantResponse.storeList : [];

    if (!nextAvailableMerchants.length && response.data && response.data.merchantResponse) {
      nextAvailableMerchants.push({
        id: response.data.merchantResponse.id,
        label: response.data.merchantResponse.companyName
      });
    }

    if (nextAvailableMerchants && nextAvailableMerchants.length > 1) {
      nextAvailableMerchants && nextAvailableMerchants.unshift(ALL_MERCHANTS_OPTION);
    }

    if (nextAvailableStores && nextAvailableStores.length > 1) {
      nextAvailableStores && nextAvailableStores.unshift(ALL_STORES_OPTION);
    }

    this.props.actions.setAvailableMerchants(nextAvailableMerchants);
    this.props.actions.setAvailableStores(nextAvailableStores);
    this.props.actions.setCurrentMerchant(
      nextAvailableMerchants && nextAvailableMerchants[0] ? nextAvailableMerchants[0].id : null
    );
    this.props.actions.setCurrentStore(
      nextAvailableStores && nextAvailableStores[0] ? nextAvailableStores[0].id : null
    );
  }

  /**
   * Request the endpoint to get user details value in a blocking way
   *
   * @returns Promise<object>
   */
  async requestUserDetails() {
    const payloadConfig = {
      payload: {
        actionName: AuthActionTypes.REQUEST_USER_DETAILS,
        errorCallback: () => this.props.actions.setShouldFetchUserDetails(false),
        nextCallback: (response) => {
          this.props.actions.setShouldFetchUserDetails(false);
          this.props.shouldFetchUserDetails && this.setGlobalControlsData(response);
        },
        headers: {
          Authorization: `Bearer ${this.props.authToken}`
        }
      },
      serviceFunction: this.props.actions.getAwaitAPIData
    };

    const userDetailsResponse = await getDataFromDataService(payloadConfig);
    return userDetailsResponse ? userDetailsResponse.data : userDetailsResponse;
  }

  /**
   * Request the endpoint to get entitlement values in a blocking way
   *
   * @returns Promise<string[]>
   */
  async requestEntitlements() {
    const payloadConfig = {
      payload: {
        actionName: AuthActionTypes.REQUEST_USER_ENTITLEMENTS
      },
      serviceFunction: this.props.actions.getAwaitAPIData
    };
    const entitlementsResponse = await getDataFromDataService(payloadConfig);
    return entitlementsResponse ? entitlementsResponse.data : entitlementsResponse;
  }

  // TODO: create the loader and entitlements notice components
  render() {
    const props = { ...this.props };
    const shouldShowLoader = !props.isInstantiated || (props.isLoading && !props.errors);
    const shouldShowPublicRoutes = props.isInstantiated && !props.isAuthenticated;
    const shouldShowPrivateRoutes = props.isInstantiated && props.isAuthenticated;
    // const shouldShowEntitlementsNotice =
    //   shouldShowPrivateRoutes && !shouldShowLoader && props.userDetails && isEmpty(props.entitlements);

    return (
      <div className={props.className}>
        <ConnectedRouter history={history}>
          {shouldShowPublicRoutes && (
            <PublicRoutes
              isAuthenticated={props.isAuthenticated}
              onLogin={this.requestUserLoginWithCredentials}
              onUserCreate={this.requestUserCreate}
            />
          )}
          {shouldShowPrivateRoutes && (
            <PrivateRoutes
              onLogout={this.logoutUser}
              isAuthenticated={props.isAuthenticated}
              entitlements={props.entitlements}
            />
          )}
        </ConnectedRouter>

        {/* {shouldShowEntitlementsNotice && <div>No entitlements</div>} */}

        {props.children}

        {shouldShowLoader && <Spinner />}
      </div>
    );
  }
}

export default AppOrchestrator;
