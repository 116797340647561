/**
 * Convert a tiny float to percentage avoiding the floating point issue
 * more info: https://stackoverflow.com/questions/588004/is-floating-point-math-broken
 *
 * @param {number} value
 * @param {number} [places]
 *
 * @returns number | string
 */
const toPercentage = (value, places = 3) => {
  const expValue = isExponential(value) ? value * 100 : value * 100 + 'e+' + places;
  return +(Math.round(Number(expValue)) + 'e-' + places);
};

/**
 * Checks if number is in exponential format (eg: 1e-8 for 0.00000001).
 * If it does not, original number is returned.
 * If it does it converts it to string representation of that number
 * which forces it to format 0.00000001
 *
 * @param {number} exponentialNumber
 *
 * @returns number | string
 */
const convertExponentialToDecimal = (exponentialNumber) => {
  // sanity check - is it exponential number
  const str = exponentialNumber.toString();

  if (isExponential(str)) {
    const exponent = Math.abs(parseInt(str.split('e')[1], 10));
    // toFixed() is used, as parseFloat() would still return the exponential representation
    return exponentialNumber.toFixed(exponent);
  } else {
    return exponentialNumber;
  }
};

/**
 * Check if a number is in exponential format
 *
 * @param {number|string} exponentialNumber
 *
 * @returns boolean
 */
const isExponential = (exponentialNumber) => {
  const str = typeof exponentialNumber === 'number' ? exponentialNumber.toString() : exponentialNumber;
  return str.indexOf('e') > -1;
};

/**
 * Transform a number to intl formatted string
 * more info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
 *
 * @param {number} value
 * @param {string} [numberFormat]
 * @param {number} [minimumFractionDigits]
 *
 * @returns string
 */
const toIntlString = (value, numberFormat = 'en', minimumFractionDigits = 2) => {
  return new Intl.NumberFormat(numberFormat, {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: minimumFractionDigits
  }).format(value);
};

/**
 * Transform a number to specific float point digits
 *
 * @param {number | string} value
 * @param {number} places
 *
 * @returns number
 */
const setPrecision = (value, places = 4) => {
  const expValue = isExponential(value)
    ? convertExponentialToDecimal(Number(value)) + 'e+' + places
    : value + 'e+' + places;
  return +(Math.round(Number(expValue)) + 'e-' + places);
};

/**
 * Trim to specific length
 *
 * @param {number | string} value
 * @param {number} maxLength
 *
 * @returns number | string
 */
const setValueMaxLength = (value, maxLength = 5) => {
  const strValue = typeof value === 'string' ? value : value.toString();
  return strValue.slice(0, maxLength);
};

/**
 * Format to amount
 *
 * @param {number | string} value
 * @param {string} currency
 * @param {number} minimumFractionDigits
 * @param {string} numberFormat
 * @param {string} currencyDisplay
 *
 * @returns string
 */
const formatAmount = (
  value,
  currency,
  minimumFractionDigits = 2,
  numberFormat = 'en',
  currencyDisplay = 'narrowSymbol'
) => {
  const formatterOptions = {
    minimumFractionDigits,
    currencyDisplay
  };

  if (!!currency) {
    formatterOptions.style = 'currency';
    formatterOptions.currency = currency;
  } else {
    formatterOptions.style = 'decimal';
  }
  let currencyReplaced = false;

  const formatValueFromParts = (item, idx, arr) => {
    const nextCurrency = arr[idx + 1] && arr[idx + 1].type === 'currency' && arr[idx + 1].value;
    if ((item.type === 'currency' || item.type === 'literal') && currencyReplaced) {
      return '';
    }

    if (item.type === 'minusSign' && nextCurrency && !currencyReplaced) {
      currencyReplaced = true;
      return `${nextCurrency} ${item.value}`;
    }
    return `${item.value}`;
  };

  const defaultLocale = 'en';
  const valueFormatter = new Intl.NumberFormat(numberFormat ?? defaultLocale, formatterOptions);

  return valueFormatter
    .formatToParts(value)
    .map(formatValueFromParts)
    .join('')
    .replace(/^(\D+)/, '$1 ');
};

export {
  convertExponentialToDecimal,
  isExponential,
  toPercentage,
  toIntlString,
  setPrecision,
  setValueMaxLength,
  formatAmount
};
