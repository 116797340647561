import isNull from 'lodash/isNull';
import moment from 'moment';
import { FormLabel, FormGroup, TextField } from '@material-ui/core';
import { formatAmount } from '../../utilities/Math';
import StatusCustomBodyRender from './components/StatusCustomBodyRender/StatusCustomBodyRender';
import AmountCustomBodyRender from './components/AmountCustomBodyRender/AmountCustomBodyRender';
import DateCustomBodyRender from './components/DateCustomBodyRender/DateCustomBodyRender';
import CurrencyCustomBodyRender from './components/CurrencyCustomBodyRender/CurrencyCustomBodyRender';

const DATE_FORMAT = 'DD/MM/YYYY';

const customSearch = (searchQuery, currentRow) => {
  let isFound = false;
  const searchQueryToUppercase = searchQuery.toUpperCase();

  const clearedCurrentRow = currentRow.filter((element) => element !== undefined);

  clearedCurrentRow.forEach((col) => {
    if (typeof col === 'object') {
      const formattedAmountToString = formatAmount(col.amount, col.currency).toUpperCase();
      if (formattedAmountToString.indexOf(searchQueryToUppercase) >= 0) {
        isFound = true;
      }
    }

    const date = moment.utc(col, 'YYYY-MM-DD');

    const isMatchingQuery =
      (date.isValid() && date.format('DD/MM/YYYY').toUpperCase().indexOf(searchQueryToUppercase) >= 0) ||
      col.toString().toUpperCase().indexOf(searchQueryToUppercase) >= 0;
    if (isMatchingQuery) {
      isFound = true;
    }
  });
  return isFound;
};

const onDownload = (buildHead, buildBody, columns, data) => {
  const normalisedDataSet = data.map((dataItem) => {
    const normalisedData =
      dataItem && dataItem.data
        ? dataItem.data.map((dataArrayItem) =>
            dataArrayItem && dataArrayItem.amount ? dataArrayItem.amount : dataArrayItem
          )
        : [];

    return {
      ...dataItem,
      data: normalisedData
    };
  });

  return buildHead(columns) + buildBody(normalisedDataSet);
};

const amountColumnDefOptions = {
  customBodyRender: (value, tableMeta, updateValue) => (
    <AmountCustomBodyRender amount={value.amount} currency={value.currency} />
  ),
  sortCompare: (order) => {
    return (obj1, obj2) => {
      const amountA = obj1.amount;
      const amountB = obj2.amount;

      return (amountA < amountB ? -1 : 1) * (order === 'asc' ? 1 : -1);
    };
  },
  filter: true,
  filterType: 'custom',
  filterList: [],
  customFilterListOptions: {
    render: (v) => {
      const lowerBoundary = v[0];
      const upperBoundary = v[1];

      if (!isNull(lowerBoundary) && !isNaN(lowerBoundary) && !isNull(upperBoundary) && !isNaN(upperBoundary)) {
        return [`Min Amount: ${lowerBoundary}`, `Max Amount: ${upperBoundary}`];
      } else if (!isNull(lowerBoundary) && !isNaN(lowerBoundary)) {
        return `Min Amount: ${lowerBoundary}`;
      } else if (!isNull(upperBoundary) && !isNaN(upperBoundary)) {
        return `Max Amount: ${upperBoundary}`;
      }

      return false;
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    }
  },
  filterOptions: {
    names: [],
    logic(value, filters) {
      const lowerBoundary = parseFloat(filters[0]);
      const upperBoundary = parseFloat(filters[1]);

      if (!isNull(lowerBoundary) && !isNaN(lowerBoundary) && !isNull(upperBoundary) && !isNaN(upperBoundary)) {
        return value.amount < lowerBoundary || value.amount > upperBoundary;
      } else if (!isNull(lowerBoundary) && !isNaN(lowerBoundary)) {
        return value.amount < lowerBoundary;
      } else if (!isNull(upperBoundary) && !isNaN(upperBoundary)) {
        return value.amount > upperBoundary;
      }

      return false;
    },
    display: (filterList, onChange, index, column) => (
      <div>
        <FormLabel>{column.label}</FormLabel>
        <FormGroup row>
          <TextField
            type={'number'}
            label='min'
            value={filterList[index][0] || ''}
            onChange={(event) => {
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%', marginRight: '5%' }}
          />
          <TextField
            type={'number'}
            label='max'
            value={filterList[index][1] || ''}
            onChange={(event) => {
              filterList[index][1] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%' }}
          />
        </FormGroup>
      </div>
    )
  }
};

const dateColumnDefOptions = {
  customBodyRender: (value, tableMeta, updateValue) => <DateCustomBodyRender date={value} />,
  sortCompare: (order) => {
    return (obj1, obj2) => {
      const dateA = moment.utc(obj1.data, 'YYYY-MM-DDThh:mm:SSZ');
      const dateB = moment.utc(obj2.data, 'YYYY-MM-DDThh:mm:SSZ');

      return (dateB.isAfter(dateA) ? -1 : 1) * (order === 'asc' ? 1 : -1);
    };
  },
  sortOrder: 'asc',
  filter: true,
  filterType: 'custom',
  filterList: [],
  customFilterListOptions: {
    render: (v) => {
      const lowerBoundary = v[0] ? moment.utc(v[0], 'YYYY-MM-DDThh:mm:SSZ') : null;
      const upperBoundary = v[1] ? moment.utc(v[1], 'YYYY-MM-DDThh:mm:SSZ') : null;

      if (lowerBoundary && upperBoundary) {
        return [`Min Date: ${lowerBoundary.format(DATE_FORMAT)}`, `Max Date: ${upperBoundary.format(DATE_FORMAT)}`];
      } else if (lowerBoundary) {
        return `Min Date: ${lowerBoundary.format(DATE_FORMAT)}`;
      } else if (upperBoundary) {
        return `Max Date: ${upperBoundary.format(DATE_FORMAT)}`;
      }

      return false;
    },
    update: (filterList, filterPos, index) => {
      if (filterPos === 0) {
        filterList[index].splice(filterPos, 1, '');
      } else if (filterPos === 1) {
        filterList[index].splice(filterPos, 1);
      } else if (filterPos === -1) {
        filterList[index] = [];
      }

      return filterList;
    }
  },
  filterOptions: {
    names: [],
    logic(value, filters) {
      const date = moment.utc(value, 'YYYY-MM-DD');
      const lowerBoundary = filters[0] ? moment.utc(filters[0]) : null;
      const upperBoundary = filters[1] ? moment.utc(filters[1]) : null;

      if (lowerBoundary && upperBoundary) {
        return date.isBefore(lowerBoundary) || date.isAfter(upperBoundary);
      } else if (lowerBoundary) {
        return date.isBefore(lowerBoundary);
      } else if (upperBoundary) {
        return date.isAfter(upperBoundary);
      }

      return false;
    },
    display: (filterList, onChange, index, column) => (
      <div>
        <FormLabel>{column.label}</FormLabel>
        <FormGroup row>
          <TextField
            type={'date'}
            label='min'
            value={filterList[index][0] || ''}
            onChange={(event) => {
              filterList[index][0] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%', marginRight: '5%' }}
          />
          <TextField
            type={'date'}
            label='max'
            value={filterList[index][1] || ''}
            onChange={(event) => {
              filterList[index][1] = event.target.value;
              onChange(filterList[index], index, column);
            }}
            style={{ width: '45%' }}
          />
        </FormGroup>
      </div>
    )
  }
};

const transactionsColumnDefs = [
  {
    name: 'id',
    label: 'Transaction Id'
  },
  {
    name: 'payer_id',
    label: 'Player Id',
    options: {
      display: false
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      display: false
    }
  },
  {
    name: 'currency',
    label: 'Currency',
    options: {
      display: false,
      customBodyRender: (value) => <CurrencyCustomBodyRender currency={value} />
    }
  },
  {
    hide: true,
    name: 'initialAmount',
    label: 'Initial Amount',
    options: {
      ...amountColumnDefOptions,
      display: false
    }
  },
  {
    hide: true,
    name: 'savingAmount',
    label: 'Saving Amount',
    options: {
      ...amountColumnDefOptions,
      display: false
    }
  },
  {
    name: 'amount',
    label: 'Amount',
    options: amountColumnDefOptions
  },
  {
    name: 'date',
    label: 'Date',
    options: dateColumnDefOptions
  },
  {
    name: 'status',
    label: 'Status',
    options: {
      customBodyRender: (value) => <StatusCustomBodyRender status={value} />
    }
  }
];

const transactionsGridOptions = (type) => ({
  enableNestedDataAccess: '.',
  filterType: 'multiselect',
  selectableRows: 'none',
  pagination: true,
  resizableColumns: false,
  selectableRowsHeader: false,
  rowHover: true,
  fixedHeader: true,
  rowsPerPage: 10,
  rowsPerPageOptions: [10, 15, 100],
  filter: true,
  sortFilterList: true,
  sort: true,
  search: true,
  print: true,
  viewColumns: true,
  download: true,
  downloadOptions: {
    filename: type === 'inbound' ? 'robinpayInboundTransactions.csv' : 'robinpayOutboundTransactions.csv',
    separator: ',',
    filterOptions: {
      useDisplayedColumnsOnly: true,
      useDisplayedRowsOnly: true
    }
  },
  customSearch,
  onDownload
});

export { amountColumnDefOptions, dateColumnDefOptions, transactionsColumnDefs, transactionsGridOptions };
