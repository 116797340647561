import React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { Box, IconButton, Link } from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiLinkedin as LinkedinIcon,
  mdiInstagram as InstagramIcon
} from '@mdi/js';
import useStyles from './styles';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import AccountOverview from '../../pages/accountOverview/AccountOverview';
import Transactions from '../../pages/transactions/Transactions';
import Profile from '../../pages/profile/Profile';
import Dashboard from '../../pages/dashboard/Dashboard';
import Typography from '../../pages/typography/Typography';
import Notifications from '../../pages/notifications/Notifications';
import Maps from '../../pages/maps/Maps';
import Tables from '../../pages/tables/Tables';
import Icons from '../../pages/icons/Icons';
import Charts from '../../pages/charts/Charts';
import { useLayoutState } from '../../context/LayoutContext';

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <Box className={classes.root}>
      <>
        <Header history={props.history} onLogout={props.onLogout} />
        <Sidebar />
        <Box
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}>
          <Box className={classes.fakeToolbar} />
          <Box flex={1}>
            <Switch>
              <Route path='/app/overview' component={AccountOverview} />
              <Route path='/app/transactions' component={Transactions} />
              <Route path='/app/profile' component={Profile} />
              <Route path='/app/dashboard' component={Dashboard} />
              <Route path='/app/typography' component={Typography} />
              <Route path='/app/tables' component={Tables} />
              <Route path='/app/notifications' component={Notifications} />
              <Route exact path='/app/ui' render={() => <Redirect to='/app/ui/icons' />} />
              <Route path='/app/ui/maps' component={Maps} />
              <Route path='/app/ui/icons' component={Icons} />
              <Route path='/app/ui/charts' component={Charts} />
            </Switch>
          </Box>
          <Box mt={5} width={'100%'} display={'flex'} alignItems={'center'} justifyContent='space-between'>
            <Box>
              <Link
                color={'primary'}
                href={'https://www.robinpayapp.com/index.html'}
                target={'_blank'}
                className={classes.link}>
                robinpay website
              </Link>
            </Box>
            <Box>
              <Link href={'https://www.facebook.com/robinpayapp'} target={'_blank'}>
                <IconButton aria-label='facebook'>
                  <Icon path={FacebookIcon} size={1} color='#ff004c' />
                </IconButton>
              </Link>
              <Link href={'https://twitter.com/robinpayapp'} target={'_blank'}>
                <IconButton aria-label='twitter'>
                  <Icon path={TwitterIcon} size={1} color='#ff004c' />
                </IconButton>
              </Link>
              <Link href={'https://www.instagram.com/robinpayapp'} target={'_blank'}>
                <IconButton aria-label='twitter'>
                  <Icon path={InstagramIcon} size={1} color='#ff004c' />
                </IconButton>
              </Link>
              <Link href={'https://www.linkedin.com/company/robinpayapp'} target={'_blank'}>
                <IconButton aria-label='linkedin' style={{ marginRight: -12 }}>
                  <Icon path={LinkedinIcon} size={1} color='#ff004c' />
                </IconButton>
              </Link>
            </Box>
          </Box>
        </Box>
      </>
    </Box>
  );
}

export const LayoutWithRouter = withRouter(Layout);
