import React from 'react';
import { Paper, Typography, makeStyles, Divider } from '@material-ui/core';
import { formatAmount } from '../../../../utilities/Math';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.primary.main}`,
    background: theme.palette.primary.contrastText,
    padding: theme.spacing(2)
  },
  valueOfTransactionsText: {
    color: theme.palette.primary.main
  },
  volumeOfTransactionsText: {
    color: theme.palette.warning.main
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));

const TransactionsComposedChartTooltip = ({ active, payload, label }) => {
  const classes = useStyles();
  if (active && payload && payload.length) {
    const payloadEntries = Object.entries(payload[0].payload);

    return (
      <Paper key={label} elevation={1} className={classes.container}>
        <>
          {payloadEntries.map(([key, value]) => {
            if (key === 'volumeOfTransactions') {
              return (
                <Typography key={key} variant='subtitle1' className={classes.volumeOfTransactionsText}>
                  Volume of Transactions: {value / 1000}
                </Typography>
              );
            }

            if (key === 'valueOfTransactions') {
              return (
                <Typography key={key} variant='subtitle1' gutterBottom className={classes.valueOfTransactionsText}>
                  Value of Transactions: {formatAmount(value, payload[0].payload.currency)}
                </Typography>
              );
            }

            if (key === 'fullDate') {
              return (
                <React.Fragment key={key}>
                  <Divider className={classes.divider} />
                  <Typography variant='body2' gutterBottom>
                    Date: {label}
                  </Typography>
                </React.Fragment>
              );
            }

            if (key === 'currency') {
              return (
                <Typography key={key} variant='body2' gutterBottom>
                  Currency: {value}
                </Typography>
              );
            }
            return null;
          })}
        </>
      </Paper>
    );
  }

  return null;
};

export { TransactionsComposedChartTooltip };
