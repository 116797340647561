import { ROBINPAY_YAPILY_SERVICE_ID } from '../../constants';
import { getServiceHostEndpoint } from '../../utilities/UrlDiscovery';
import { PaymentsTypes } from './types';

const paymentsEndpointHandler = (payload, endpointDetails) => {
  switch (payload.actionName) {
    case PaymentsTypes.REQUEST_PAYMENTS:
      endpointDetails = {
        url: getServiceHostEndpoint(ROBINPAY_YAPILY_SERVICE_ID, '/payments/domestic/qrcode'),
        data: payload.data,
        headers: payload.headers,
        nextCallback: payload.nextCallback,
        errorCallback: payload.errorCallback
      };
      break;

    default:
      break;
  }

  return endpointDetails;
};

export default paymentsEndpointHandler;
