const MessageDictionary = Object.freeze({
  NO_DATA_RETRIEVAL_FUNCTION: 'No function for data retrieval is provided',
  NO_API_SERVICE_FUNCTION: 'No API service function provided.',
  NO_API_ENDPOINT: 'API endpoint configuration not provided',
  NETWORK_ERROR: 'Network Error',
  NOT_VALID_JSON_FORMAT: 'Response contains invalid JSON data but is explicitly expecting valid JSON formatted string',
  ERROR_REQUEST_CONFIGURATION: 'Error constructing request configuration',
  AUTH_TOKEN_EXPIRED: 'Connection refused or authentication token is expired/missing',
  GENERIC_ERROR: 'An error has occurred',
  AUTHENTICATION_FAILED: 'Authentication failed',
  USER_CREATION_FAILED: 'New user was not created',
  USER_CREATION_SUCCESS: 'New user has been created. Verify your email and login'
});

export { MessageDictionary };
