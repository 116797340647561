import { action } from 'typesafe-actions';
import { OrchestrateAppActionTypes } from './types';
import DataService from '../../services/DataService';

const setIsInstantiated = () => action(OrchestrateAppActionTypes.APP_INSTANTIATED);
const clearApplicationException = () => action(OrchestrateAppActionTypes.CLEAR_APPLICATION_EXCEPTION);
const throwApplicationException = (exception) => action(OrchestrateAppActionTypes.SET_APPLICATION_EXCEPTION, exception);

const getApiData = (requestConfigPayload, customErrorMsg) => {
  return DataService.doRequest(requestConfigPayload, customErrorMsg);
};

const getAwaitApiData = (requestConfigPayload, customErrorMsg) => {
  return DataService.doRequest(requestConfigPayload, customErrorMsg, true);
};

export { setIsInstantiated, clearApplicationException, throwApplicationException, getApiData, getAwaitApiData };
