import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Refresh as RefreshIcon } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import {
  Grid,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button
} from '@material-ui/core';
import useStyles from './styles';
import { getAwaitApiData } from '../../store/application/actions';
import { getDataFromDataService } from '../../utilities/DataService';
import { AccountsActionTypes } from '../../store/accounts/types';

// components
import PageTitle from '../../components/PageTitle/PageTitle';
import UserCard from './UserCard';
import MerchantCard from './MerchantCard';
import { EmptyCard } from '../../components/EmptyCard';
import { AuthActionTypes } from '../../store/auth/types';

function Profile() {
  const classes = useStyles();
  const [activeTabId, setActiveTabId] = useState(0);
  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state) => state && state.authState && state.authState.userDetails && state.authState.userDetails.userResponse
  );
  const merchantDetails = useSelector(
    (state) => state && state.authState && state.authState.userDetails && state.authState.userDetails.merchantResponse
  );
  // const adminDetails = useSelector(
  //   (state) => state && state.authState && state.authState.userDetails && state.authState.userDetails.adminResponse
  // );
  const accountsDetails = useSelector((state) => state && state.accountsState && state.accountsState.accounts);
  const isMerchant = useSelector((state) => state && state.authState && state.authState.isMerchant);
  // const isAdmin = useSelector((state) => state && state.authState && state.authState.isAdmin);
  const isLoading = useSelector(
    (state) => state && state.uiState && state.uiState.isLoading && state.uiState.isLoading.app
  );
  const errors = useSelector((state) => state && state.uiState && state.uiState.errors && state.uiState.errors.app);
  const authToken = useSelector((state) => state && state.authState && state.authState.authToken);

  const onSetFetchAccountsToFalse = useCallback(() => {
    dispatch({ type: AccountsActionTypes.SET_SHOULD_FETCH_ACCOUNTS, payload: false });
  }, [dispatch]);

  const onSetFetchUserDetailsToTrue = useCallback(() => {
    dispatch({ type: AuthActionTypes.SET_SHOULD_FETCH_USER_DETAILS, payload: { data: true } });
  }, [dispatch]);

  const getAllAccounts = useCallback(() => {
    const boundServiceFunction = (requestConfigPayload, customErrorMsg) =>
      dispatch(getAwaitApiData(requestConfigPayload, customErrorMsg));

    const requestAccounts = async () => {
      const payloadConfig = {
        payload: {
          actionName: AccountsActionTypes.REQUEST_ALL_ACCOUNTS,
          nextCallback: () => {
            onSetFetchAccountsToFalse();
            if (!userDetails) {
              onSetFetchUserDetailsToTrue();
            }
          },
          errorCallback: () => {
            onSetFetchAccountsToFalse();
            if (!userDetails) {
              onSetFetchUserDetailsToTrue();
            }
          },
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        },
        serviceFunction: boundServiceFunction
      };

      await getDataFromDataService(payloadConfig);
    };

    requestAccounts(dispatch);
  }, [dispatch, onSetFetchAccountsToFalse, authToken, onSetFetchUserDetailsToTrue, userDetails]);

  const onChangeTab = (e, id) => {
    setActiveTabId(id);
  };

  useEffect(() => {
    if (!isLoading && !errors && !accountsDetails) {
      getAllAccounts();
    }
  }, [getAllAccounts, isLoading, errors, accountsDetails]);

  return (
    <>
      <PageTitle
        title='Profile'
        button={
          <Button variant='contained' size='small' color='secondary' onClick={getAllAccounts} endIcon={<RefreshIcon />}>
            Refresh Profile
          </Button>
        }
      />

      <Tabs value={activeTabId} onChange={onChangeTab} indicatorColor='secondary' textColor='secondary'>
        <Tab label='User Details' value={0} classes={{ root: classes.tab }} />
        <Tab label='Accounts' value={1} classes={{ root: classes.tab }} />
        {merchantDetails && isMerchant && <Tab label='Merchant Details' value={2} classes={{ root: classes.tab }} />}
        {/* {merchantDetails && isMerchant && <Tab label='Stores' value={3} classes={{ root: classes.tab }} />} */}
        {/* {adminDetails && isAdmin && <Tab label='Merchants' value={4} classes={{ root: classes.tab }} />}  */}
      </Tabs>

      <Grid container spacing={4}>
        {activeTabId === 0 && (
          <Grid item xs={12}>
            <Box marginTop={2}>
              {!isLoading && userDetails && !isEmpty(userDetails) ? (
                <UserCard
                  userDetails={userDetails}
                  hasVerifiedPhone={userDetails.hasVerifiedPhone}
                  verified={userDetails.verified}
                />
              ) : (
                <EmptyCard content={{ title: 'user details' }} isLoading={isLoading} />
              )}
            </Box>
          </Grid>
        )}

        {activeTabId === 1 && (
          <Grid item xs={12}>
            <Box marginTop={2}>
              {!isLoading && accountsDetails && !isEmpty(accountsDetails) ? (
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Provider</TableCell>
                        <TableCell align='left'>Account Number</TableCell>
                        <TableCell align='left'>Sort Code</TableCell>
                        <TableCell align='left'>Currency</TableCell>
                        <TableCell align='left'>Account Type</TableCell>
                        <TableCell align='left'>Default Account</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accountsDetails.map((row) => (
                        <TableRow key={`${row.obProviderName} ${row.accountNumber}`}>
                          <TableCell component='th' scope='row'>
                            {row.obProviderName}
                          </TableCell>
                          <TableCell align='left'>{row.accountNumber}</TableCell>
                          <TableCell align='left'>{row.sortCode}</TableCell>
                          <TableCell align='left'>{row.currency}</TableCell>
                          <TableCell align='left'>{row.accountType}</TableCell>
                          <TableCell align='left'>{row.defaultAccount ? 'yes' : ''}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <EmptyCard content={{ title: 'accounts' }} isLoading={isLoading} />
              )}
            </Box>
          </Grid>
        )}

        {activeTabId === 2 && (
          <Grid item xs={12}>
            <Box marginTop={2}>
              {!isLoading && merchantDetails && isMerchant ? (
                <MerchantCard merchantDetails={merchantDetails} />
              ) : (
                <EmptyCard content={{ title: 'merchant details' }} isLoading={isLoading} />
              )}
            </Box>
          </Grid>
        )}

        {/* {activeTabId === 3 && (
          <Grid item xs={12}>
            {merchantDetails && isMerchant && merchantDetails.storeList ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Store</TableCell>
                      <TableCell align='left'>id</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchantDetails &&
                      merchantDetails.storeList &&
                      merchantDetails.storeList.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell component='th' scope='row'>
                            {row.label}
                          </TableCell>
                          <TableCell align='left'>{row.id}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyCard content={{ title: 'stores' }} />
            )}
          </Grid>
        )} */}

        {/* {!isEmpty(adminDetails) && isAdmin && activeTabId === 4 && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Merchant</TableCell>
                    <TableCell align='left'>id</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adminDetails.merchantList.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component='th' scope='row'>
                        {row.label}
                      </TableCell>
                      <TableCell align='left'>{row.id}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}

export default Profile;
