import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearApplicationException, throwApplicationException } from '../../utilities/ErrorHandling';
import ErrorBoundary from './ErrorBoundary';

const mapStateToProps = ({ uiState }, ownProps) => ({
  exceptions: uiState && uiState.exceptions,
  errors: uiState && uiState.errors
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      throwApplicationException: throwApplicationException,
      clearApplicationException: clearApplicationException
    },
    dispatch
  )
});

const ErrorBoundaryContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);

export default ErrorBoundaryContainer;
