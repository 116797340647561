import uiInitialState from './initialState';
import { MessageDictionary } from '../../utilities/MessageDictionary';
import { OrchestrateAppActionTypes } from '../application/types';
import { AuthActionTypes } from '../auth/types';
import { TransactionsActionTypes } from '../transactions/types';
import { AccountsActionTypes } from '../accounts/types';

const reducer = (state = uiInitialState, { type, payload, meta }) => {
  let newException;
  let nextIsLoading;
  let nextErrors;
  let nextExceptions;

  switch (type) {
    case AuthActionTypes.REQUEST_USER_DETAILS:
    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE:
    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS:
      nextIsLoading = { ...state.isLoading, app: true };
      nextErrors = { ...state.errors, app: null };

      return {
        ...state,
        isLoading: nextIsLoading,
        errors: nextErrors
      };

    case AuthActionTypes.REQUEST_USER_DETAILS_OK:
    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS_OK:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_OK:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE_OK:
    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS_OK:
      nextIsLoading = { ...state.isLoading, app: false };
      nextErrors = { ...state.errors, app: null };

      return {
        ...state,
        isLoading: nextIsLoading,
        errors: nextErrors
      };

    case AuthActionTypes.REQUEST_USER_DETAILS_ERR:
    case AuthActionTypes.REQUEST_USER_ENTITLEMENTS_ERR:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_ERR:
    case TransactionsActionTypes.REQUEST_ALL_TRANSACTIONS_BY_DATE_ERR:
    case AccountsActionTypes.REQUEST_ALL_ACCOUNTS_ERR:
      nextIsLoading = { ...state.isLoading, app: false };
      nextErrors = { ...state.errors, app: payload || MessageDictionary.GENERIC_ERROR };

      return {
        ...state,
        isLoading: nextIsLoading,
        errors: nextErrors
      };

    case OrchestrateAppActionTypes.SET_APPLICATION_EXCEPTION:
      newException = {};
      newException[payload.context] = payload.exception;
      nextExceptions = { ...state.exceptions, ...newException };

      return {
        ...state,
        exceptions: nextExceptions
      };

    case OrchestrateAppActionTypes.CLEAR_APPLICATION_EXCEPTION:
      nextErrors = {};
      nextErrors[payload.context] = null;
      newException = {};
      newException[payload.context] = null;
      nextExceptions = { ...state.exceptions, ...newException };

      return {
        ...state,
        exceptions: nextExceptions,
        errors: {
          ...state.errors,
          ...nextErrors
        }
      };

    case OrchestrateAppActionTypes.SET_REFRESH_TOKEN_EXCEPTION:
      newException = {};
      newException[payload.context] = payload;
      nextExceptions = { ...state.exceptions, ...newException };

      return {
        ...state,
        exceptions: nextExceptions
      };

    default:
      return state;
  }
};

export { reducer as uiReducer };
