const AuthActionTypes = Object.freeze({
  SET_SHOULD_FETCH_USER_DETAILS: '@@Auth/setShouldFetchUserDetails',
  REQUEST_USER_DETAILS: '@@Auth/RequestUserDetails',
  REQUEST_USER_DETAILS_OK: '@@Auth/RequestUserDetailsOk',
  REQUEST_USER_DETAILS_ERR: '@@Auth/RequestUserDetailsErr',
  REQUEST_USER_ENTITLEMENTS: '@@Auth/RequestUserEntitlements',
  REQUEST_USER_ENTITLEMENTS_OK: '@@Auth/RequestUserEntitlementsOk',
  REQUEST_USER_ENTITLEMENTS_ERR: '@@Auth/RequestUserEntitlementsErr',
  REQUEST_USER_LOGIN: '@@Auth/RequestUserLogin',
  REQUEST_USER_LOGIN_OK: '@@Auth/RequestUserLoginOk',
  REQUEST_USER_LOGIN_ERR: '@@Auth/RequestUserLoginErr',
  REQUEST_USER_CREATE: '@@Auth/RequestUserCreate',
  REQUEST_USER_CREATE_OK: '@@Auth/RequestUserCreateOk',
  REQUEST_USER_CREATE_ERR: '@@Auth/RequestUserCreateErr',
  SET_IS_AUTHENTICATED: '@@Auth/SetIsAuthenticated',
  CLEAR_IS_AUTHENTICATED: '@@Auth/ClearIsAuthenticated',
  SET_REFRESHED_TOKENS: '@@Auth/SetRefreshedToken',
  ON_LOGOUT: '@@Auth/OnLogout',
  SET_AUTH_STATE: '@@/Auth/SetAuthState'
});

export { AuthActionTypes };
