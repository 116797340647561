const useStyles = (theme) => ({
  fixedTopAlert: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 2,
    position: 'fixed',
    borderRadius: 0,
    top: '52px'
  }
});

export default useStyles;
