import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { setCurrentCurrency, setCurrentMerchant, setCurrentStore } from '../../../store/globalSettings';

// styles
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  label: {
    color: theme.palette.primary.white,
    '&.Mui-focused': {
      color: theme.palette.primary.white
    }
  },
  select: {
    color: theme.palette.primary.white,
    fontSize: '0.85rem'
  }
}));

// TODO: fix colors
// TODO: hide merchants and stores if user is not admin or merchant
const GlobalSettingsControls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMerchant = useSelector((state) => state && state.authState && state.authState.isMerchant);
  const isAdmin = useSelector((state) => state && state.authState && state.authState.isAdmin);
  const availableMerchants = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.availableMerchants
  );
  const availableStores = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.availableStores
  );
  const availableCurrencies = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.availableCurrencies
  );
  const currentMerchant = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentMerchant
  );
  const currentStore = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentStore
  );
  const currentCurrency = useSelector(
    (state) => state && state.globalSettingsState && state.globalSettingsState.currentCurrency
  );

  const onMerchantChange = useCallback(
    (event) => {
      const nextMerchant = event.target && event.target.value;

      dispatch(setCurrentMerchant(nextMerchant));
    },
    [dispatch]
  );

  const onStoreChange = useCallback(
    (event) => {
      const nextStore = event.target && event.target.value;

      dispatch(setCurrentStore(nextStore));
    },
    [dispatch]
  );

  const onCurrencyChange = useCallback(
    (event) => {
      const nextCurrency = event.target && event.target.value;

      dispatch(setCurrentCurrency(nextCurrency));
    },
    [dispatch]
  );

  return (
    <div>
      {(isMerchant || isAdmin) && (
        <>
          {availableMerchants && availableMerchants.length > 1 ? (
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label} htmlFor='merchant-select'>
                Merchant
              </InputLabel>
              <Select
                className={classes.select}
                defaultValue={currentMerchant}
                id='merchant-select'
                onChange={onMerchantChange}>
                {availableMerchants.map((merchantOption) => (
                  <MenuItem key={merchantOption.id} value={merchantOption.id}>
                    {merchantOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}

          {availableStores && availableStores.length ? (
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.label} htmlFor='store-select'>
                Store
              </InputLabel>
              <Select className={classes.select} defaultValue={currentStore} id='store-select' onChange={onStoreChange}>
                {availableStores &&
                  availableStores.map((storeOption) => (
                    <MenuItem key={storeOption.id} value={storeOption.id}>
                      {storeOption.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          ) : null}
        </>
      )}

      <FormControl className={classes.formControl}>
        <InputLabel className={classes.label} htmlFor='currency-select'>
          Currency
        </InputLabel>
        <Select
          className={classes.select}
          defaultValue={currentCurrency}
          id='currency-select'
          onChange={onCurrencyChange}>
          {availableCurrencies.map((currencyOption) => (
            <MenuItem key={currencyOption.id} value={currencyOption.id}>
              {currencyOption.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default GlobalSettingsControls;
