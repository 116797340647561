import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography
  //  Tabs, Tab
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import useStyles from './styles';
import logo from '../../images/robinpay.svg';
import LoginForm from './components/LoginForm';

function Login(props) {
  const classes = useStyles();
  const [activeTabId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // const onTabChange = (e, id) => {
  //   setActiveTabId(id);
  //   setError(null);
  // };

  return (
    <Grid container className={classes.container}>
      <Box className={classes.logotypeContainer}>
        <img src={logo} alt='robinpay' className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>
          Save fees on every payment
          <br />
          Give cashback to your clients
          <br />
          <Box component='span' className={classes.pink}>
            Build customer loyalty
          </Box>
        </Typography>
      </Box>

      <Box className={classes.formContainer}>
        <Box className={classes.form}>
          {/* <Tabs value={activeTabId} onChange={onTabChange} indicatorColor='secondary' textColor='secondary' centered>
            <Tab label='Login' classes={{ root: classes.tab }} />
            {/* <Tab label='New User' classes={{ root: classes.tab }} /> */}
          {/* </Tabs> */}

          {activeTabId === 0 && (
            <LoginForm
              history={props.history}
              onLogin={props.onLogin}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              setError={setError}
            />
          )}

          {/* {activeTabId === 1 && (
            <CreateUserForm
              history={props.history}
              onUserCreate={props.onUserCreate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              error={error}
              setError={setError}
            />
          )} */}
        </Box>

        <Typography color='primary' className={classes.copyright}>
          © 2019 Inqredi Limited. Inqredi Limited is a company registered in England and Wales (No. 11782190).
        </Typography>
      </Box>
    </Grid>
  );
}

export default withRouter(Login);
