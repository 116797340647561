import isEmpty from 'lodash/isEmpty';

let serviceDiscoveryList;

class ServiceDiscoveryFactory {
  constructor(serviceDiscoveryListItems) {
    if (serviceDiscoveryListItems && !isEmpty(serviceDiscoveryListItems)) {
      serviceDiscoveryList = serviceDiscoveryListItems;
    }
  }

  getServiceDiscoveryList() {
    return serviceDiscoveryList;
  }
}

export default ServiceDiscoveryFactory;
