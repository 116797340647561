import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Menu as MenuIcon, Person as AccountIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { Typography } from '../Wrappers/Wrappers';
import classNames from 'classnames';
import { useLayoutState, useLayoutDispatch, toggleSidebar } from '../../context/LayoutContext';
import { useUserDispatch, signOut } from '../../context/UserContext';
import GlobalSettingsControls from './components/GlobalSettingsControls';
import { formatAmount } from '../../utilities/Math';
import useStyles from './styles';
import logo from '../../images/robinpay.svg';
import { isEmpty } from 'lodash';

export default function Header(props) {
  const classes = useStyles();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const [profileMenu, setProfileMenu] = useState(null);

  const userDetails = useSelector(
    (state) => state && state.authState && state.authState.userDetails && state.authState.userDetails.userResponse
  );

  const renderUserTotalSavings = () => {
    const amounts = [];

    if (userDetails && Array.isArray(userDetails.totalSavings) && !isEmpty(userDetails.totalSavings)) {
      userDetails.totalSavings.forEach(({ amount, currency }) => {
        amounts.push(formatAmount(amount, currency));
      });
    }

    if (isEmpty(amounts)) {
      return null;
    }

    return amounts.map((amount) => (
      <Typography key={amount} variant='body2' weight='medium'>
        Total Savings: {`${amount}`}
      </Typography>
    ));
  };

  const isProfileMenuOpen = Boolean(profileMenu);

  const iconButtonChildrenRootClassNames = {
    root: classNames(classes.headerIcon, classes.headerIconCollapse)
  };

  const onToggleProfileMenu = (e) => {
    if (!isProfileMenuOpen) {
      setProfileMenu(e.currentTarget);
      return;
    }
    setProfileMenu(null);
  };

  const onToggleSidebar = () => {
    toggleSidebar(layoutDispatch);
  };

  const onSignOut = () => {
    signOut(userDispatch, props.history, props.onLogout);
  };

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color='inherit'
          onClick={onToggleSidebar}
          className={classNames(classes.headerMenuButtonSandwich, classes.headerMenuButtonCollapse)}>
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon classes={iconButtonChildrenRootClassNames} />
          ) : (
            <MenuIcon classes={iconButtonChildrenRootClassNames} />
          )}
        </IconButton>
        <Typography variant='h6' weight='medium' className={classes.logotype}>
          <img
            src={logo}
            alt='robinpay'
            className={classes.logotypeImage}
            style={{ marginBottom: '-5px', marginRight: '8px' }}
          />
          Dashboard
        </Typography>
        <Box className={classes.grow} />
        <GlobalSettingsControls />
        <IconButton
          aria-haspopup='true'
          color='inherit'
          className={classes.headerMenuButton}
          aria-controls='profile-menu'
          onClick={onToggleProfileMenu}>
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id='profile-menu'
          open={isProfileMenuOpen}
          anchorEl={profileMenu}
          onClose={onToggleProfileMenu}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem>
          <Box className={classes.profileMenuUser}>
            <Typography variant='h4' weight='medium'>
              Hello {`${userDetails ? userDetails.displayName : 'User'}`}
            </Typography>
            {renderUserTotalSavings()}
          </Box>
          <MenuItem component={Link} to='/app/profile'>
            <AccountIcon className={classes.profileMenuIcon} /> Profile
          </MenuItem>
          <Box className={classes.profileMenuUser}>
            <Typography className={classes.profileMenuLink} color='primary' onClick={onSignOut}>
              Sign Out
            </Typography>
          </Box>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}
