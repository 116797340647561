import { OrchestrateAppActionTypes } from './types';
import applicationInitialState from './initialState';

const reducer = (state = applicationInitialState, { type, payload, meta }) => {
  switch (type) {
    case OrchestrateAppActionTypes.REQUEST_SERVICE_DISCOVERY_OK:
      return {
        ...state,
        serviceDiscovery: state.env ? payload.data[state.env] : []
      };

    case OrchestrateAppActionTypes.SET_APPLICATION_EXCEPTION:
      // Show up to 10 exceptions at the same time
      return {
        ...state,
        exception: [...state.exception.slice(0, 9), payload]
      };

    case OrchestrateAppActionTypes.CLEAR_APPLICATION_EXCEPTION:
      return {
        ...state,
        exception: []
      };

    case OrchestrateAppActionTypes.APP_INSTANTIATED:
      return {
        ...state,
        isInstantiated: true
      };

    default:
      return state;
  }
};

export { reducer as applicationReducer };
