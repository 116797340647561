import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import { CookiesProvider } from 'react-cookie';
import Themes from './themes';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';

import { Provider as ReduxProvider } from 'react-redux';
import { history } from './store/configureStore';
import { store } from './store';
import configureEndpointHandlers from './store/configureEndpointHandlers';

console.log('%c UI Version:', 'color: #4CAF50; font-weight: bold', process.env.REACT_APP_VERSION);
console.log('%c Build Mode:', 'color: #4CAF50; font-weight: bold', process.env.NODE_ENV);

configureEndpointHandlers();

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <CookiesProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <ReduxProvider store={store}>
            <App history={history} />
          </ReduxProvider>
        </ThemeProvider>
      </CookiesProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
