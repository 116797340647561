import React from 'react';

// styles
import useStyles from './styles';
import { CircularProgress } from '@material-ui/core/es/index';

export default function Spinner(props) {
  const classes = useStyles();

  return (
    <div className={classes.backdrop}>
      <div className={classes.centerChild}>
        <CircularProgress color='secondary' />
      </div>
    </div>
  );
}
