import { action } from 'typesafe-actions';
import { GlobalSettingsActionTypes } from './types';

const setCurrentMerchant = (merchant) => {
  return action(GlobalSettingsActionTypes.SET_CURRENT_MERCHANT, merchant);
};

const setCurrentStore = (store) => {
  return action(GlobalSettingsActionTypes.SET_CURRENT_STORE, store);
};

const setCurrentCurrency = (currency) => {
  return action(GlobalSettingsActionTypes.SET_CURRENT_CURRENCY, currency);
};

const setAvailableMerchants = (merchants) => {
  return action(GlobalSettingsActionTypes.SET_AVAILABLE_MERCHANTS, merchants);
};

const setAvailableStores = (stores) => {
  return action(GlobalSettingsActionTypes.SET_AVAILABLE_STORES, stores);
};

const setAvailableCurrencies = (currencies) => {
  return action(GlobalSettingsActionTypes.SET_AVAILABLE_CURRENCIES, currencies);
};

export {
  setCurrentMerchant,
  setCurrentStore,
  setCurrentCurrency,
  setAvailableMerchants,
  setAvailableStores,
  setAvailableCurrencies
};
