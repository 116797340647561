import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { applicationReducer } from './application/reducer';
import { authReducer } from './auth/reducer';
import { uiReducer } from './ui/reducer';
import { transactionsReducer } from './transactions/reducer';
import { globalSettingsReducer } from './globalSettings/reducer';
import { accountsReducer } from './accounts';
import { createBrowserHistory } from 'history';
import initialState from './initialState';
import { AuthActionTypes } from './auth/types';

const appReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    applicationState: applicationReducer,
    authState: authReducer,
    uiState: uiReducer,
    transactionsState: transactionsReducer,
    globalSettingsState: globalSettingsReducer,
    accountsState: accountsReducer
  });

const createRootReducer = (state, action) => {
  const history = createBrowserHistory();
  if (action.type === AuthActionTypes.ON_LOGOUT) {
    state = {
      ...initialState,
      applicationState: { ...initialState.applicationState, isInstantiated: true }
    };
  }
  return appReducer(history)(state, action);
};

export default createRootReducer;
