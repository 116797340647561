import React from 'react';
import { formatAmount } from '../../../../utilities/Math';

const AmountCustomBodyRender = (props) => {
  const formattedAmount = formatAmount(props.amount, props.currency);

  return <span>{formattedAmount}</span>;
};

export default AmountCustomBodyRender;
