import React, { useState, useEffect, useMemo } from 'react';
import { Box, LinearProgress } from '@material-ui/core';
import useStyles from '../../styles';
import Widget from '../../../../components/Widget/Widget';
import Dot from '../../../../components/Sidebar/components/Dot';
import { Typography } from '../../../../components/Wrappers/Wrappers';
import { dashboardTransactionsChartDataNormalizer, fullDateComparisonPredicate } from '../../../../utilities/chart';
import { isEmpty } from 'lodash';
import { useFilteringDates } from '../../../../hooks';
import { dataResolverForUseEffect } from '../../../../utilities/generic';
import { EmptyCard } from '../../../../components/EmptyCard';
import { formatAmount } from '../../../../utilities/Math';

const DailyTransactionsWidget = ({ data, selectedCurrency, isLoading }) => {
  const [state, setState] = useState([]);
  const { startDate, endDate } = useFilteringDates('daily', true);

  useEffect(() => {
    if (data || !isEmpty(data)) {
      dataResolverForUseEffect(dashboardTransactionsChartDataNormalizer, [data, 'fullDate'], setState);
    }
  }, [data]);

  const filteredData = useMemo(() => {
    if (typeof startDate === 'number' && typeof endDate === 'number' && !isEmpty(state)) {
      const predicate = fullDateComparisonPredicate(startDate, endDate, selectedCurrency);
      return state.filter(predicate);
    }
    return state;
  }, [startDate, endDate, state, selectedCurrency]);

  const classes = useStyles();

  if (isLoading || isEmpty(filteredData)) {
    return (
      <Widget
        title={`Daily Transactions in ${selectedCurrency}`}
        upperTitle
        className={classes.card}
        disableWidgetMenu
        bodyClass={classes.fullHeightBody}>
        <EmptyCard isLoading={isLoading} content={{ title: 'transactions' }}>
          Sorry, no matching records found
        </EmptyCard>
      </Widget>
    );
  }

  return (
    <Widget
      title={`Daily Transactions in ${selectedCurrency}`}
      upperTitle
      className={classes.card}
      disableWidgetMenu
      bodyClass={classes.fullHeightBody}>
      <Box className={classes.performanceLegendWrapper}>
        <Box className={classes.legendElement}>
          <Dot color='warning' />
          <Typography color='text' colorBrightness='secondary' className={classes.legendElementText}>
            Volume of transactions
          </Typography>
        </Box>
        <Box className={classes.legendElement}>
          <Dot color='primary' />
          <Typography
            color='text'
            variant='subtitle1'
            colorBrightness='secondary'
            className={classes.legendElementText}>
            Value of transactions
          </Typography>
        </Box>
      </Box>
      <Box className={classes.progressSection}>
        <Typography color='text' variant='body2' colorBrightness='secondary' className={classes.progressSectionTitle}>
          Volume of transactions: {`${filteredData[0].volumeOfTransactions / 1000}`}
        </Typography>
        <LinearProgress
          variant='determinate'
          value={filteredData[0].volumeOfTransactions / 1000}
          classes={{ barColorPrimary: classes.progressBarWarning }}
          className={classes.progress}
        />
      </Box>
      <Box>
        <Typography color='text' variant='body2' colorBrightness='secondary' className={classes.progressSectionTitle}>
          Value of transactions: {`${formatAmount(filteredData[0].valueOfTransactions, selectedCurrency)}`}
        </Typography>
        <LinearProgress
          variant='determinate'
          value={filteredData[0].valueOfTransactions / 100}
          classes={{ barColorPrimary: classes.progressBarPrimary }}
          className={classes.progress}
        />
      </Box>
    </Widget>
  );
};

export { DailyTransactionsWidget };
